import axios from 'axios';
import { setAuthToken } from './axiosconfg';




const loginUser = async (email, password) => {
    try {
        const response = await axios.post(
            'api/login',
            {
                email: email,
                password: password,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        const token = response.data.access_token;
        setAuthToken(token);
        localStorage.setItem("AdminID",response.data.id);
        localStorage.setItem("AdminName", response.data.name);

        return token;
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};
const Logoutuser = async () =>{
    const res = await axios.post('api/logout');
    return res;

};

const Forgetpassuser = async (payload) =>{
    const res = await axios.post('api/forget-password',payload);
    return res;

};
const fetchUserStatistics = async () => {
    
    try {
        const response = await axios.get('api/user-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching user statistics:', error);
        throw error;
    }
};

const fetchUserdata = async () => {
    try {
       
        const response = await axios.get('/api/users?sortByJoinedAt=true',);
        return response.data.content;
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
};
const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`/api/user?userId=${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchsupportmessage = async (userId) => {
    try {
        
        const response = await axios.get(`/api/support-messages?userId=${userId}`);
       // const response = await axios.get(`api/support-messages?userId=6529ccd9b71bf5480dbcf562`);

        return response;
    } catch (error) {
        console.error('Error fetching user support massge:', error);
        throw error;
    }
};
const fetchUserproduct = async (userId) => {
    try {
       
        const response = await axios.get(`/api/user-items?userId=${userId}`);
        return response;
    } catch (error) {
        console.error('Error fetching user support massge:', error);
        throw error;
    }
};

const handleStauts = async (id,statuss) =>{
    try{
        const capitalizedStatus = statuss.toUpperCase();
        const res = await axios.patch(`api/update-account-status`,
        {
            "userId": id,
            "status": capitalizedStatus
        })
        return res;
    }catch(error){
        console.error('Error fetching user account-status:', error);
        throw error;
    }
};

const fetchSwapActivity = async () => {
    try {
       
        const response = await axios.get('api/swap-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching swap statistics:', error);
        throw error;
    }
};

const fetchproductStat = async () => {
    try {
       
        const response = await axios.get('api/items-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching product statistics:', error);
        throw error;
    }
};

const fetchBehaviourStat = async () => {
    try {
       
        const response = await axios.get('api/user-behavior-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching behaviour statistics:', error);
        throw error;
    }
};

 const createHandleOpenMessgae = async (IssueId) =>{
     try{
         const res = await axios.patch(`api/change-support-status?issueId=${IssueId}`)
         return res;
     }catch(error){
         console.error('Error fetching user support massge:', error);
         throw error;
     }
 };

 const fetchFullUserStat = async () => {
    try {
       
        const response = await axios.get('api/full-users-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching full-users statistics:', error);
        throw error;
    }
};

const handleBulkAction = async (status,items) =>{
    try{
        const res = await axios.patch(`api/item-bulk-action`, {
            action: status,
            items: items,
          });
        return res;
    }catch(error){
        console.error('Error fetching bulk action :', error);
        throw error;
    }
};
const fetchproductDetails = async (productId) => {
    try {
       
        const response = await axios.get(`/api/item?itemId=${productId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching product detail:', error);
        throw error;
    }
};
const createLocation = async (payload) => {
    try {
      const response = await axios.post('api/location', payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const UpdateLocation = async (id , payload ) => {
    try {
      const response = await axios.patch(`api/location?locationId=${id}`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const DeleteLocation = async (locationId) => {
    try {
      const response = await axios.delete(`api/location?locationId=${locationId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const fetchLocation = async () => {
    try {
       
        const response = await axios.get('api/locations',);
        return response.data;
    } catch (error) {
        console.error('Error fetching locations data:', error);
        throw error;
    }
};

const fetchInterest = async () => {
    try {
       
        const response = await axios.get('api/interests',);
        return response.data;
    } catch (error) {
        console.error('Error fetching interest data:', error);
        throw error;
    }
};
const createIntrest = async (payload) => {
    try {
      const response = await axios.post('api/interest',{
        "name": payload
      });
      return response;
    } catch (error) {
      throw error;
    }
  }; 
  const Updateinterst = async (id , payload ) => {
    try {
      const response = await axios.patch(`api/interest?interestId=${id}`,{
        "name": payload
      },{headers: {
        'Content-Type': 'application/json'},
    });
      return response;
    } catch (error) {
      throw error;
    }
  };
const fetchAdmin = async () => {
    try {
       
        const response = await axios.get('api/admins',);
        return response.data;
    } catch (error) {
        console.error('Error fetching admin data:', error);
        throw error;
    }
};
const createAdmin = async (payload) => {
    try {
      const response = await axios.post('api/admin',payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const DeleteAdmin = async (admintobedel,adminid) => {
    try {
      const response = await axios.delete(`/api/admin?adminIdToBeDeleted=${admintobedel}&adminId=${adminid}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const changePasswordApi = async (payload) => {
    try {
       const adminid =localStorage.getItem("AdminID")
      const response = await axios.post(`api/change-password?id=${adminid}`,payload);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const fetchCategories = async () => {
    try {
       
        const response = await axios.get('api/categories',);
        return response.data;
    } catch (error) {
        console.error('Error fetching categories data:', error);
        throw error;
    }
}; 
const fetchAllProduct = async (fromDate, toDate, selectedConditions, selectedStatus,selectedCategory,selectedLocation ) => {
    try {
      let apiUrl = `api/items?`;
      if(fromDate){
        apiUrl += `&fromDate=${fromDate}`;
      }
      if(toDate){
        apiUrl += `&toDate=${toDate}`;
      }
  
      if (selectedConditions) {
          apiUrl += `&itemCondition=${selectedConditions}`;
        
      }

      if (selectedStatus) {
          apiUrl += `&itemStatus=${selectedStatus}`;
        
      }
      if (selectedCategory) {
          apiUrl += `&categoryId=${selectedCategory}`;
        
      }
      if (selectedLocation) {
        apiUrl += `&locationId=${selectedLocation}`;
      
    }
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const updateStautsButtom = async (payload)=>{
    try {
      const response = await axios.patch(`/api/update-item-status`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const fetchReports = async () => {
    try {
       
        const response = await axios.get('api/generatedReports',);
        return response.data;
    } catch (error) {
        console.error('Error fetching reports data:', error);
        throw error;
    }
};
const GenrateNewReport =async (payload)=>{
  try{
    const res =await axios.post('api/generateNewReport',payload);
    return res.data;


  }catch(error){
    throw error;
  }
};
const GenrateReport = async (reportId)=>{
  try{
    const res = await axios.get(`/api/generatedReport?reportId=${reportId}`);
    return res.data;
  }
  catch(error){
    throw error;
  }
}
  const HandleEditCategory =async (CategoryId,payload) =>{
    try{
      const res =await axios.patch(`/api/category?categoryId=${CategoryId}`,payload);
      return res;


    }
    catch(error){
      throw error
    }
  };

  const HandleNewImageSubmit = async (payload) =>{
    try{
      const res =await axios.post(`/api/main-category`,payload);
      return res;
    }
    catch(error){
      throw error
    }
  };
  

  const HandleSubNewImageSubmit = async (selectedMainCategory,payload) =>{
    try{
      const res =await axios.post(`/api/sub-category?mainCategoryId=${selectedMainCategory}`,payload);
      return res;
    }
    catch(error){
      throw error
    }
  };

  const DeleteSubCategory = async (subCategoryId) => {
    try {
      const response = await axios.delete(`/api/sub-category?subCategoryId=${subCategoryId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

export {  loginUser, createHandleOpenMessgae, fetchUserStatistics ,fetchUserdata ,fetchsupportmessage ,fetchUserproduct ,handleStauts,
     fetchSwapActivity, fetchproductStat, fetchBehaviourStat, fetchFullUserStat ,fetchAllProduct,handleBulkAction ,fetchproductDetails ,
     createLocation, fetchLocation, fetchInterest, fetchAdmin,UpdateLocation , DeleteLocation,createIntrest,Updateinterst,createAdmin,DeleteAdmin,
     changePasswordApi, Logoutuser,Forgetpassuser, fetchCategories,updateStautsButtom, fetchReports,GenrateNewReport,GenrateReport ,
     HandleEditCategory,HandleNewImageSubmit,HandleSubNewImageSubmit,DeleteSubCategory,deleteUser};