import Header from "../../global/Header";
import "../statDashboard/statisticsDashboard.css";
import { Link } from "react-router-dom";
import { ProgressBar, Spinner } from "react-bootstrap";
import { fetchFullUserStat, fetchproductStat, fetchBehaviourStat } from "../../global/axios";
import { useState, useEffect, useCallback } from "react";
import Toast from 'react-bootstrap/Toast';
const StatisticsDashboard = () => {
    const [fullUserStats, setfullUserStats] = useState(null);
    const [productStats, setProductStats] = useState(null);
    const [behaviourStats, setBehaviourStats] = useState(null);
    const [statusToast, setStatusToast] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            const data = await fetchFullUserStat();
            setfullUserStats(data);
        } catch (error) {
            console.error("Error fetching user statistics:", error);
            setStatusToast({ status: 'error', message: 'Error fetching user statistics' });
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const fetchData2 = useCallback(async () => {
        try {
          await new Promise((resolve) => setTimeout(resolve, 500));
          const data = await fetchproductStat();
          setProductStats(data);
        } catch (error) {
          console.error("Error fetching product statistics:", error);
          setStatusToast({ status: 'error', message: 'Error fetching product statistics' });
        }
      }, []);

      useEffect(() => {
        fetchData2();
      }, [fetchData2]);

      const fetchData3 = useCallback(async () => {
        try {
          await new Promise((resolve) => setTimeout(resolve, 500));
          const data = await fetchBehaviourStat();
          setBehaviourStats(data);
        } catch (error) {
          console.error("Error fetching behaviour statistics:", error);
          setStatusToast({ status: 'error', message: 'Error fetching behaviour statistics' });
        }
      }, []);

      useEffect(() => {
        fetchData3();
      }, [fetchData3]);  

    if (fullUserStats === null || productStats === null || behaviourStats === null) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }
    
    return (
        <div style={{width:"88%"}}>
            <Header title={"Statistics"} />
            <div className="topDiv">
                <div className="titleDiv"> User Statistics </div>
                <Link to="/userStatistics" style={{ textDecoration: "none" }}>
                    <button className="viewAll">
                        View All
                    </button>
                </Link>
            </div>
            <div style={{width:"99%"}}>
                <div className="boxes">
                    <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                        <div className="boxTitle">{fullUserStats.totalNoOfRegisteredUsers}</div>
                        <div className="boxSubtitle">Total No. Of Registered Users</div>
                    </div>
                    <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                        <div className="boxTitle">{fullUserStats.activeUsersStatistics.totalMonthly}</div>
                        <div className="boxSubtitle">Monthly Active Users</div>
                    </div>
                    <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                        <div className="boxTitle">
                            {fullUserStats.newUsersStatistics.totalMonthly}
                        </div>
                        <div className="boxSubtitle">
                            Monthly New Users
                        </div>
                    </div>
                    <div className="box" style={{ background: "#D43A2F", border: "#A71F1F" }}>
                        <div className="boxTitle" style={{ color: "white" }}>
                            {fullUserStats.suspendedUsersStatistics.totalMonthly}
                        </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                            Monthly Suspended Users
                        </div>
                    </div>
                </div>
            </div>
            <div className="topDiv">
                <div className="titleDiv"> Behavioural Statistics </div>
                <Link to="/behaviour" style={{ textDecoration: "none" }}>
                    <button className="viewAll">
                        View All
                    </button>
                </Link>
            </div>
            <div style={{ height: "240px", marginBottom:"20px", marginLeft:"20px" , width:"96%" }}>
                    <div className="chart" style={{ marginLeft: "0px", width: "300px", marginTop: "18px" }}>
                        <div className="chartTitle" style={{ paddingBottom: "10px" }}>
                            Top Interest
                        </div>
                        <div>
                            {behaviourStats.topInterests.slice(0, 5).map((interest,index) => (
                                <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                    <div className="barLabel">{interest.interest}</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar className="progressBar" now={interest.percentage} max="100" />
                                    </div>
                                    <div className="barValue">{interest.percentage.toFixed(2)}%</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            <div style={{width:"99%"}}>
            <div className="boxes">
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }} >
                    <div className="boxTitle" style={{ color: "white" }}>
                      {(behaviourStats?.swipeRatio?.leftCount || 0)}:{(behaviourStats?.swipeRatio?.rightCount || 0)}
                    </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Swipe Right to Left Ratio</div>
                    </div>
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }} >
                        <div className="boxTitle" style={{ color: "white" }}>{behaviourStats.countOfSuccessfulSwapRequests}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Total Accepted Swaps this month</div>
                    </div>
                </div>
            </div>
            <div className="topDiv">
                <div className="titleDiv"> Products Statistics </div>
                <Link to="/productStatistics" style={{ textDecoration: "none" }}>
                    <button className="viewAll">
                        View All
                    </button>
                </Link>
            </div>
            <div style={{width:"99%", marginBottom:"20px"}}>
            <div className="boxes" >
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }} >
                        <div className="boxTitle" style={{ color: "white" }}>{productStats.totalItems}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Total No. of Listings</div>
                    </div>
                    <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                        <div className="boxTitle">{productStats.totalActiveItems}</div>
                        <div className="boxSubtitle">Total No. of Active Listings</div>
                    </div>
                    <div className="box" style={{ background: "#9A9A9A", border: "#A9A9A9" }}>
                        <div className="boxTitle" style={{ color: "white" }}>
                            {productStats.totalExpiredItems}
                        </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                            Total No. of Expired Listings
                        </div>
                    </div>
                    <div className="box" style={{ background: "#D43A2F", border: "#A71F1F" }}>
                        <div className="boxTitle" style={{ color: "white" }}>
                            {productStats.totalSuspendedItems}
                        </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                            Total No. of Suspended Listings
                        </div>
                    </div>
                </div>
            </div>
            <div style={{color:"#121212"}}>.</div>
            {statusToast && (
                    <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                        <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            <div >
                                {statusToast.status === 'success' ? 'Success' : 'Error'}
                            </div>
                        </Toast.Header>
                        <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                    </Toast>
                )}
        </div>
    );
};

export default StatisticsDashboard;