import React, { useState,useEffect } from "react";
import '../Login/login.css';
import { Navigate } from "react-router-dom";
import { loginUser, Forgetpassuser } from "../global/axios";
import { ReactComponent as CloseIcon } from "../products/CloseIcon.svg";
import { Toast } from "react-bootstrap";

const Login = (props) => {
  const [password, SetPassword] = useState('');
  const [email, Setemail] = useState('');
  const [navigate, Setnavigate] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [Forgetemail, setForgetemail] = useState('');
  const [statusToast, setStatusToast] = useState(null);
  const [statusToast2, setStatusToast2] = useState(null);
  useEffect(() => {
    // Check if there's an auth token in local storage
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      // Clear existing auth token
      localStorage.removeItem("authToken");
      // You might also want to clear other related data
      localStorage.removeItem("AdminID");
      localStorage.removeItem("AdminName");
      // ... any other cleanup actions
    }
  }, []);
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');


    try {
      await loginUser(email, password);
      const loginTime = new Date().getTime();
      localStorage.setItem('loginTime', loginTime);
      Setnavigate(true);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setStatusToast({ status: 'error', message: 'Wrong Password. Please try again.' });
      } else {
        setStatusToast({ status: 'error', message: 'An error occurred. Please try again.' });
      }
    }
  };
  //useEffect(() => {localStorage.clear();})

  const HandleForget = async () => {
    try {
        const payload = {
            "email": Forgetemail
        }
        const res = await Forgetpassuser(payload);
        setStatusToast2({ status: 'success', message: 'Password sent successfully. Check your Email!' });
        return res;
    } catch (error) {
        console.error('Error sending password:', error);
        setStatusToast2({ status: 'error', message: 'Wrong Email. Please try again!' });
    }
};

  if (navigate) {
    return <Navigate to="/dashboard" />
  }
  return (
    <div className="loginForm" style={{ display: "block" }}>
      <div className="loginTitle">
        <svg className="swapIcon" xmlns="http://www.w3.org/2000/svg" width="128px" height="34px" viewBox="0 0 128 34" fill="none">
          <path d="M24.7179 0.33694H33.542C33.7807 1.59154 34.0194 2.84131 34.2564 4.08946C34.4919 5.32955 34.7241 6.56964 34.9563 7.80973C35.1917 9.06272 35.4175 10.3189 35.661 11.5703C35.8997 12.8007 36.1093 14.0376 36.3689 15.2567C36.6092 15.2406 36.585 15.0938 36.6076 14.9842C36.8769 13.7102 37.1446 12.4363 37.4123 11.1623C38.1444 7.66782 38.8765 4.17331 39.6103 0.678811C39.6328 0.573992 39.6667 0.472398 39.7006 0.346615H48.2619C48.8101 2.82679 49.3568 5.30052 49.9019 7.77425C50.4421 10.2302 50.9775 12.6862 51.529 15.139C51.5693 15.1422 51.6096 15.1454 51.6499 15.1471C51.687 15.0616 51.7418 14.9793 51.758 14.8907C52.0837 13.1216 52.4046 11.351 52.7255 9.58197C53.269 6.58415 53.8108 3.58472 54.3543 0.586893C54.3688 0.509488 54.3994 0.435308 54.4284 0.341778H63.2284C61.1836 8.50637 59.1437 16.6484 57.0989 24.8098H47.5571C47.5088 24.6308 47.4572 24.4518 47.4104 24.2728C46.3412 20.2106 45.2737 16.1469 44.2029 12.0847C44.1642 11.9396 44.1917 11.7106 43.9885 11.7025C43.7401 11.6913 43.7853 11.9509 43.7466 12.0992C42.6548 16.2259 41.5712 20.3541 40.4859 24.4808C40.4585 24.584 40.4278 24.6856 40.3924 24.8098H30.8538C28.8139 16.6677 26.774 8.52411 24.7211 0.335327L24.7179 0.33694Z" fill="white" />
          <path d="M99.2958 20.8589C99.2538 21.9523 99.2829 23.0456 99.278 24.139C99.2732 25.2275 99.278 26.316 99.278 27.4045V34H90.52C90.4426 33.7243 90.42 0.856254 90.5023 0.348285H99.1974C99.2055 0.485356 99.22 0.627265 99.22 0.767561C99.2216 1.89638 99.22 3.0252 99.2216 4.15402C99.2216 4.29432 99.2345 4.43461 99.2425 4.57491C99.2732 4.5862 99.3054 4.59587 99.3361 4.60716C99.3909 4.53298 99.4522 4.46364 99.499 4.38462C99.7876 3.88633 100.03 3.35256 100.368 2.89136C101.644 1.1562 103.38 0.22089 105.524 0.0499543C108.125 -0.159684 110.413 0.607914 112.34 2.36081C113.766 3.65895 114.661 5.30703 115.215 7.13733C115.833 9.17888 115.967 11.2801 115.904 13.3974C115.857 14.9601 115.675 16.5065 115.211 18.0046C114.627 19.8833 113.716 21.5749 112.235 22.9037C110.636 24.3405 108.741 25.0775 106.591 25.1517C105.754 25.1807 104.925 25.1259 104.114 24.9098C102.032 24.3534 100.515 23.1085 99.5683 21.1702C99.5135 21.0573 99.4522 20.946 99.3941 20.8348C99.3619 20.8428 99.3296 20.8509 99.2974 20.8573L99.2958 20.8589ZM99.0893 12.6976C99.0797 13.3507 99.1555 14.1521 99.3377 14.9391C99.5441 15.8276 99.9134 16.6355 100.517 17.3338C101.721 18.7271 104.182 18.74 105.378 17.4128C105.933 16.7984 106.286 16.084 106.494 15.2987C106.927 13.6619 106.943 12.009 106.607 10.3529C106.438 9.51591 106.144 8.72574 105.612 8.04522C104.377 6.46326 102.016 6.32619 100.599 7.74851C100.065 8.28389 99.7279 8.93699 99.4957 9.64492C99.1877 10.5851 99.0894 11.5575 99.0861 12.6976H99.0893Z" fill="white" />
          <path d="M72.7928 8.29193H64.7249C64.6911 7.87588 64.7749 7.48563 64.8636 7.10183C65.499 4.34106 67.1761 2.43336 69.6998 1.2223C71.1124 0.545003 72.6154 0.219258 74.1635 0.0821872C75.7487 -0.058109 77.3322 -0.025857 78.9045 0.233772C81.0428 0.588544 83.0408 1.29002 84.7212 2.71718C86.0596 3.85406 86.924 5.27153 87.1917 7.02282C87.2449 7.3663 87.261 7.71785 87.261 8.06617C87.2659 13.5087 87.2642 18.9512 87.2642 24.3921V24.8098H79.0206V21.3717C78.8981 21.4346 78.8223 21.4556 78.7755 21.504C78.7207 21.5604 78.6917 21.6394 78.6497 21.7104C77.6402 23.4213 76.1728 24.5195 74.2328 24.9485C72.1703 25.4048 70.1207 25.3387 68.1114 24.6614C65.5377 23.7955 63.9444 21.683 63.7654 18.9754C63.6977 17.9498 63.7171 16.9306 63.9622 15.9308C64.4008 14.1457 65.4216 12.7846 67.0068 11.8477C68.1565 11.1672 69.4063 10.7592 70.7141 10.5576C71.8946 10.3754 73.0879 10.2948 74.2731 10.14C75.0972 10.0335 75.918 9.89002 76.7356 9.73843C76.9952 9.69006 77.2516 9.58524 77.4935 9.47235C78.6336 8.93697 78.7949 7.43725 77.8015 6.65192C77.4677 6.38745 77.0823 6.2278 76.6695 6.15363C75.9454 6.02462 75.2246 6.03591 74.5183 6.26167C73.6426 6.54065 73.0701 7.10506 72.8718 8.02102C72.8557 8.09842 72.8266 8.17583 72.7944 8.28709L72.7928 8.29193ZM78.5433 14.1586C78.3917 14.1844 78.3385 14.186 78.2901 14.2037C77.229 14.6036 76.1276 14.8342 75.0101 15.0068C74.3167 15.1148 73.6426 15.2938 73.0508 15.6986C72.2413 16.2517 71.8784 17.1419 72.0994 18.0901C72.3009 18.9544 72.9266 19.3931 73.7361 19.5705C74.9811 19.843 76.1421 19.5963 77.1758 18.8464C77.8515 18.3546 78.3433 17.6998 78.432 16.8581C78.524 15.9808 78.5078 15.0939 78.5401 14.1602L78.5433 14.1586Z" fill="white" />
          <path d="M0.0258016 16.9483H8.70804C8.73384 17.0225 8.76932 17.1064 8.79189 17.1934C8.99831 18.003 9.49015 18.5867 10.2432 18.9205C11.472 19.464 12.7428 19.5059 14.0167 19.0931C14.3021 19.0012 14.5827 18.8302 14.8085 18.6319C15.3342 18.1675 15.3503 17.3902 14.8327 16.9193C14.5715 16.6823 14.2425 16.4791 13.9071 16.3759C13.2298 16.1678 12.5331 16.013 11.8365 15.8792C10.2158 15.5679 8.58387 15.3083 6.96804 14.9777C5.75214 14.7294 4.59591 14.2892 3.54449 13.6102C1.5997 12.3573 0.619238 10.5624 0.557959 8.25642C0.52732 7.07277 0.68213 5.9246 1.18849 4.84254C1.89642 3.33154 3.06716 2.28173 4.52334 1.52059C5.8231 0.841683 7.21638 0.456272 8.65805 0.240183C10.6093 -0.0516971 12.567 -0.072661 14.5231 0.162779C16.1066 0.353065 17.637 0.740089 19.0642 1.47705C20.0398 1.98018 20.9154 2.61716 21.6492 3.43958C22.8554 4.78933 23.4149 6.39386 23.5133 8.17901C23.5133 8.2032 23.4988 8.229 23.4843 8.27899H15.4229C15.4003 8.19836 15.3713 8.1129 15.3536 8.0242C15.2117 7.3195 14.8037 6.79701 14.1941 6.45192C12.8734 5.70367 11.5027 5.68916 10.132 6.29872C9.46596 6.59544 9.21117 7.12115 9.35308 7.7678C9.45951 8.24997 9.77719 8.587 10.2174 8.76439C10.6625 8.94177 11.1302 9.08852 11.601 9.17882C13.3814 9.51747 15.1681 9.81741 16.9484 10.1528C18.3933 10.4238 19.7963 10.8317 21.0831 11.5639C22.1442 12.167 23.0263 12.9507 23.573 14.0666C24.0455 15.0326 24.2051 16.0566 24.1487 17.1209C24.0068 19.8107 22.6393 21.7636 20.4462 23.2036C18.9271 24.2002 17.2387 24.7662 15.4568 25.0452C12.7605 25.4677 10.0739 25.3871 7.41635 24.742C5.54412 24.2889 3.86056 23.4648 2.45599 22.1151C1.08044 20.7863 0.282205 19.1689 0.0145134 17.2838C0.0048378 17.2176 0 17.1499 0 17.0822C0 17.0435 0.0145134 17.0064 0.0258016 16.9467V16.9483Z" fill="white" />
          <path d="M118.908 20.9073C118.8 18.5691 120.858 16.4678 123.362 16.4775C125.77 16.4872 127.86 18.5142 127.863 20.8783C127.866 23.2698 125.792 25.342 123.386 25.3581C120.813 25.3742 118.821 23.252 118.908 20.9073Z" fill="white" />
        </svg>
      </div>
      <div className="AuthFormContainer" style={{ justifyContent: "center" }}>
        <form className="AuthForm text-light" onSubmit={handleLogin} >
          <h3 className="AuthFormTitle">Sign In</h3>
          <div className="entry2" style={{ marginTop: "30px" }}>
            <div className="entryTitle">Email Address:</div>
            <input
              onChange={e => Setemail(e.target.value)}
              type="email"
              className="entryInput2"
              style={{ width: "180px" }}
            />
          </div>
          <div className="entry2">
            <div className="entryTitle">Password:</div>
            <input
              onChange={e => SetPassword(e.target.value)}
              type="password"
              className="entryInput2"
              style={{ width: "180px" }}

            />
          </div>
          <div className="d-grid gap-2 mt-4">
            <button type="submit" className="submitCreation" style={{ background: "#252525", border: "none", width: "160px", fontWeight: "700" }}>
              Submit
            </button>


          </div>
          <div className="forgotPassword text-right mt-3">
            <div type="button" style={{ color: "#9A9A9A", marginLeft: "3px", textDecoration: "underline" }} onClick={() => setShowOverlay(true)}>Forgot password?</div>
          </div>
          {loginError && <div className="error-message" style={{ color: 'red' }}>{loginError}</div>}
          {showOverlay && (
            <>
              <div className="overlayBackground" onClick={() => setShowOverlay(false)}></div>
              <div className="overlayTopbar" style={{paddingRight:"25px"}} >
                <div className="createOverlayTopbar">
                  <CloseIcon onClick={() => setShowOverlay(false)} type="button" className="closeButton3" style={{width:"20px", height:"20px"}} />
                  <div className="createOverlayTitle2"> <div>Forget Password </div>
                  </div>
                  <div className="entry">
                    <div className="entryTitle" style={{marginLeft:"25px"}} >Email:</div>
                    <input className="entryInput" type="email" onChange={e => setForgetemail(e.target.value)}></input>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                    <div className="submitCreation" type="button" onClick={() => HandleForget()}> Submit </div>
                  </div>
                </div>
                {statusToast2 && (
                    <Toast onClose={() => setStatusToast2(null)} autohide className="toastMessage">
                        <Toast.Header className={`toastHeader ${statusToast2.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            <div >
                                {statusToast2.status === 'success' ? 'Success' : 'Error'}
                            </div>
                        </Toast.Header>
                        <Toast.Body className="toastBody">{statusToast2.message}</Toast.Body>
                    </Toast>
                )}
              </div>
            </>
          )}
        </form>
      </div>
      {statusToast && (
                    <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                        <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            <div >
                                {statusToast.status === 'success' ? 'Success' : 'Error'}
                            </div>
                        </Toast.Header>
                        <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                    </Toast>
                )}
    </div>
  );
};
export default Login;
