// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart {
    width: 300px;
    height: 100%;
    flex-shrink: 0;
    margin: 20px;
    border-radius: 5px;
    border: 2px solid #252525;
    background: #030303;
    padding: 18px;
}

.chartTitle {
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px !important;
    
}

.progressBar {
    flex: 1 1;
    margin: 10px;
    height: 5px !important;
    background-color: #343434 !important;
    width: 100%;
    margin-bottom: 15px;
    margin-right: 10px;
}

.barValue {
    color: #FFF;
    text-align: right;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 30px;
    margin-bottom: 15px;

}

.barLabel {
    color: #FFF;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: uppercase;
    width: 65px;
    margin-right: 2px;
    margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/globalItems/Chart/chart.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,8BAA8B;;AAElC;;AAEA;IACI,SAAO;IACP,YAAY;IACZ,sBAAsB;IACtB,oCAAoC;IACpC,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;;AAEvB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".chart {\n    width: 300px;\n    height: 100%;\n    flex-shrink: 0;\n    margin: 20px;\n    border-radius: 5px;\n    border: 2px solid #252525;\n    background: #030303;\n    padding: 18px;\n}\n\n.chartTitle {\n    color: #FFF;\n    font-family: Montserrat;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    margin-bottom: 10px !important;\n    \n}\n\n.progressBar {\n    flex: 1;\n    margin: 10px;\n    height: 5px !important;\n    background-color: #343434 !important;\n    width: 100%;\n    margin-bottom: 15px;\n    margin-right: 10px;\n}\n\n.barValue {\n    color: #FFF;\n    text-align: right;\n    font-family: Montserrat;\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 300;\n    line-height: normal;\n    text-transform: uppercase;\n    margin-left: 30px;\n    margin-bottom: 15px;\n\n}\n\n.barLabel {\n    color: #FFF;\n    font-family: Montserrat;\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 300;\n    line-height: normal;\n    text-transform: uppercase;\n    width: 65px;\n    margin-right: 2px;\n    margin-bottom: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
