
import axios from 'axios';

const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        document.cookie = `authToken=${token}; HttpOnly; Secure; SameSite=None`;
        localStorage.setItem('authToken', token);
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};
const initializeAuth = () => {
    const authToken =  localStorage.getItem('authToken');
    if (authToken) {
        setAuthToken(authToken);
    }
};



export { setAuthToken ,initializeAuth };
