// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast{
    background-color: black !important;
    color: aliceblue !important;
    position: absolute !important;
    bottom: 10px !important;
    right: 10px !important;
    border: 2px #252525 solid !important;
}
.toastHeader{
    background: black !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/globalItems/ToastMessage/toastMessage.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,2BAA2B;IAC3B,6BAA6B;IAC7B,uBAAuB;IACvB,sBAAsB;IACtB,oCAAoC;AACxC;AACA;IACI,4BAA4B;AAChC","sourcesContent":[".toast{\n    background-color: black !important;\n    color: aliceblue !important;\n    position: absolute !important;\n    bottom: 10px !important;\n    right: 10px !important;\n    border: 2px #252525 solid !important;\n}\n.toastHeader{\n    background: black !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
