// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Source+Sans+3:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    margin-top: 60px;
    margin-left: 20px;
    width: 560px;
    height: fit-content;
}

.title{
    color: #FFF;
    font-family: Montserrat;
    font-size: 27px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 5px;
    letter-spacing: 1.5px;
    
}

`, "",{"version":3,"sources":["webpack://./src/pages/global/Header.css"],"names":[],"mappings":"AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,qBAAqB;;AAEzB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Source+Sans+3:wght@400;600;700&display=swap');\n.header{\n    margin-top: 60px;\n    margin-left: 20px;\n    width: 560px;\n    height: fit-content;\n}\n\n.title{\n    color: #FFF;\n    font-family: Montserrat;\n    font-size: 27px;\n    font-style: normal;\n    font-weight: 800;\n    line-height: normal;\n    margin-bottom: 5px;\n    letter-spacing: 1.5px;\n    \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
