// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.locationTable {
    border: 2px solid #252525 !important;
    margin-top: 20px;
    margin-left: 20px;
    width: 80vw;
    padding-left: 10px !important;
}

.createOverlay {
    height: 65%;
    width: 95%;
    background: #121212;
    margin-Left: 10px;
    padding: 15px;
    color: #9A9A9A;
    border: 2px solid #252525;
    padding-Top: 25px;
    justify-Content: center;
    align-Self: center;
}

.deleteButton {
    background: #FF0000;
    width: fit-content;
    padding: 10px;
    border-radius: 4px;
    color: #FFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.createOverlayTitle {
    color: #FFF;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 25px;
}

.entryTitle {
    margin-right: 10px;
    
}

.entryInput:focus {
    background: #121212;
    
}

.entryInput {
    border: #252525 2px solid;
    width: 270px !important;
}

.entry{
    display: flex;
    align-Items: center;
    margin-Bottom: 15px;
    width:100%;
    justify-Content:space-between; 
}`, "",{"version":3,"sources":["webpack://./src/pages/locations/locations.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,UAAU;IACV,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;;AAEtB;;AAEA;IACI,mBAAmB;;AAEvB;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,UAAU;IACV,6BAA6B;AACjC","sourcesContent":[".locationTable {\n    border: 2px solid #252525 !important;\n    margin-top: 20px;\n    margin-left: 20px;\n    width: 80vw;\n    padding-left: 10px !important;\n}\n\n.createOverlay {\n    height: 65%;\n    width: 95%;\n    background: #121212;\n    margin-Left: 10px;\n    padding: 15px;\n    color: #9A9A9A;\n    border: 2px solid #252525;\n    padding-Top: 25px;\n    justify-Content: center;\n    align-Self: center;\n}\n\n.deleteButton {\n    background: #FF0000;\n    width: fit-content;\n    padding: 10px;\n    border-radius: 4px;\n    color: #FFF;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}\n\n.createOverlayTitle {\n    color: #FFF;\n    font-family: Montserrat;\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    margin-bottom: 25px;\n}\n\n.entryTitle {\n    margin-right: 10px;\n    \n}\n\n.entryInput:focus {\n    background: #121212;\n    \n}\n\n.entryInput {\n    border: #252525 2px solid;\n    width: 270px !important;\n}\n\n.entry{\n    display: flex;\n    align-Items: center;\n    margin-Bottom: 15px;\n    width:100%;\n    justify-Content:space-between; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
