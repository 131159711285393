import { React, useState, useEffect } from "react";
import profilePic from './ProfilePic.png';
import { Dropdown, Toast } from "react-bootstrap";
import "../global/Topbar.css"
import { ReactComponent as CloseIcon } from "../products/CloseIcon.svg";
import { changePasswordApi, Logoutuser } from "./axios";
import { setAuthToken } from './axiosconfg';
import { useNavigate } from "react-router-dom";

const Topbar = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [AdminName, setAdminName] = useState('');
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [changePasswordMessage, setChangePasswordMessage] = useState("");
    const [statusToast, setStatusToast] = useState(null);
    const navigate = useNavigate();


    const AdminNameRet = async () => {
        const adminname = localStorage.getItem("AdminName");
        setAdminName(adminname);

    };
    useEffect(() => {

        AdminNameRet();
        const checkLoginStatus = () => {
            const loginTime = localStorage.getItem('loginTime');
            if (loginTime) {
                const currentTime = new Date().getTime();
                const twoHours = 2 * 60 * 60 * 1000;
                const timePassed = currentTime - parseInt(loginTime);

                if (timePassed >= twoHours) {
                    localStorage.removeItem('loginTime');
                    logoutUser();
                    navigate('/');
                }
            }
        }

        setInterval(checkLoginStatus, 50000); // Check every minute

    }, [navigate]);

    const handleSubmit = async () => {
        try {
            if (newPassword !== confirmNewPassword) {
                setStatusToast({ status: 'error', message: 'Passwords do not match' });
                return;
            }
            setPasswordError("");
            const payload = {
                "currentPassword": currentPassword,
                "newPassword": newPassword,
                "confirmNewPassword": confirmNewPassword
            };

            const response = await changePasswordApi(payload);
            if (response.status === 200) {
                setStatusToast({ status: 'success', message: 'Password changed successfully!' });
                setTimeout(() => {
                    setChangePasswordMessage("");
                }, 20000);

            } else {
                setChangePasswordMessage("Failed to change password");
                setStatusToast({ status: 'error', message: 'Error changing password' });

                setTimeout(() => {
                    setStatusToast("");
                }, 20000);
            }
        } catch (error) {
            console.error("Error changing password:", error.message);
            setStatusToast({ status: 'error', message: 'Error changing password' });

        }

        setTimeout(() => {
            setStatusToast("");
        }, 20000);
    };
    const logoutUser = async () => {
        try {

            await Logoutuser();
            localStorage.removeItem("AdminID");
            localStorage.removeItem("AdminName");
            localStorage.removeItem("authToken");

            setAuthToken(null);
            window.location.href = '/';

        } catch (error) {
            console.error('Error during logout:', error);
            setStatusToast({ status: 'error', message: 'Error during logout' });
        }
    };

    return (

        <div style={{ maxHeight: "fit-content", maxWidth: "fit-content", position: "absolute", top: "15px", right: "15px" }}>
            <Dropdown>
                <Dropdown.Toggle className="sideBarMenu" style={{ paddingBottom: "0px" }}>
                    <div style={{ width: '100%', height: '100%', background: '#252525', borderRadius: "27px", display: "flex", alignItems: "center" }}>
                        <img style={{ width: '22px', height: '22px', borderRadius: 9999, margin: "4px 4px 4px 4px" }} src={profilePic} alt="ProfilePic" />
                        <div style={{ width: "fit-content", opacity: 0.5, color: 'white', fontSize: 11, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', display: "inline", marginRight: "5px" }}>{AdminName}</div>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="productActions" type="button" style={{ borderRadius: "2px" }}>
                    <div className="actionSidebar" onClick={() => setShowOverlay(true)}>Change Password</div>
                    <div className="actionSidebar" type="button" onClick={() => logoutUser()} >Logout </div>
                </Dropdown.Menu>
            </Dropdown>

            {showOverlay && (
                <>
                    <div className="overlayBackground" onClick={() => setShowOverlay(false)}></div>
                    <div className="overlayTopbar" >
                        <div className="createOverlay3" >
                            <CloseIcon onClick={() => setShowOverlay(false)} type="button" className="closeButton2" />
                            <div className="createOverlayTitle2" style={{ marginBottom: "30px" }}> <div>Change Password </div>
                            </div>
                            <div className="entry">
                                <div className="entryTitle" >Current Password:</div>
                                <input className="entryInput" type="password" onChange={(e) => setCurrentPassword(e.target.value)}></input>
                            </div>
                            <div className="entry">
                                <div className="entryTitle">New Password:</div>
                                <input className="entryInput" type="password" onChange={(e) => setNewPassword(e.target.value)}></input>
                            </div>
                            <div className="entry">
                                <div className="entryTitle">Confirm New Password:</div>
                                <input className="entryInput" type="password" onChange={(e) => setConfirmNewPassword(e.target.value)}></input>
                                {passwordError && <div style={{ color: "red" }}>{passwordError}</div>}
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                <div className="submitCreation" type="button" onClick={handleSubmit}> Submit </div>
                            </div>
                            {changePasswordMessage && <div style={{ color: "green" }}>{changePasswordMessage}</div>}
                        </div>
                        {statusToast && (
                            <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                                <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                                    <div >
                                        {statusToast.status === 'success' ? 'Success' : 'Error'}
                                    </div>
                                </Toast.Header>
                                <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                            </Toast>
                        )}
                    </div>

                </>

            )}

        </div>
    )
}

export default Topbar;