import Header from "../global/Header";
import "../products/products.css";
import "../products/list.css";
import { Link } from "react-router-dom";
import { Dropdown, Spinner, Pagination } from "react-bootstrap";
import { ReactComponent as CloseIcon } from "../products/CloseIcon.svg";
import { fetchAllProduct, handleBulkAction, updateStautsButtom, fetchCategories, fetchLocation } from "../global/axios";
import { useEffect, useState } from 'react';
import Eye from "../products/group.png";
import { Toast } from "react-bootstrap";
//import { Category } from "@mui/icons-material";

const Products = () => {
    const [activeTab, setActiveTab] = useState('List');
    const [showOverlay, setShowOverlay] = useState(false);
    const [AllproductData, setAllproductsData] = useState([]);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [selectedAction, setSelectedAction] = useState('Boost Actions');
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [imageLoadError, setImageLoadError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const [selectedConditions, setSelectedCondition] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [categoriesData, setCategoriesData] = useState(null);
    const [locationData, setLocationData] = useState(null);
    const [statusToast, setStatusToast] = useState(null);
    const [selectAll, setSelectAll] = useState(false);

    const Allproduct = async () => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 200));
            const products = await fetchAllProduct();
            setAllproductsData(products);

        } catch (error) {
            console.error('Error fetching all products:', error);
            setStatusToast({ status: 'error', message: 'Error fetching products' });
        }
    };
    const fetchCateg = async () => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 200));
            const data = await fetchCategories();
            setCategoriesData(data);

        } catch (error) {
            console.error("Error fetching categories data:", error);
            setStatusToast({ status: 'error', message: 'Error fetching categories data' });
        }
    };
    const FetchLocation = async () => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            const data = await fetchLocation();
            setLocationData(data);
        } catch (error) {
            console.error("Error fetching location data:", error);
            setStatusToast({ status: 'error', message: 'Error fetching location data' });
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                Allproduct();
                fetchCateg();
                FetchLocation();
                await new Promise(resolve => setTimeout(resolve, 5000));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching all products:', error);
                setLoading(false); // Set loading to false in case of an error
                setStatusToast({ status: 'error', message: 'Error fetching products' });
            }
        };
        fetchData();

    }, []);
    const filteredList = searchTerm
        ? AllproductData.filter((product) =>
            product.title && product.title.toLowerCase().includes(searchTerm)
        )
        : AllproductData;


    let totalPages, currentItems;
    if (filteredList) {
        totalPages = Math.ceil(filteredList.length / itemsPerPage);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);
    };
    const handlePageChange = (pageNumber) => {
        setCurrentPage(() => pageNumber);
    };
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>
        );
    }
    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);

        setCurrentPage(1);

    };
    const handleImageError = () => {
        // This function will be called if the image fails to load
        setImageLoadError(true);
        setStatusToast({ status: 'error', message: 'Image fails to load' });
    };
    const handleActionChange = (action) => {
        setSelectedAction(action);

    };


    const handleApplyClick = async (selectedAction) => {
        try { 
            setLoading(true);
            if (selectedProductIds.length > 0) {
                const response = await handleBulkAction(selectedAction, selectedProductIds);
                await Allproduct();
                console.log('Response data:', response.data);

                // Set different toast messages based on the action
                if (selectedAction === 'BOOST') {
                    setStatusToast({ status: 'success', message: 'Product Boosted successfully!' });
                } else if (selectedAction === 'EXPIRE') {
                    setStatusToast({ status: 'success', message: 'Product Boost Expired successfully!' });
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(true);
            console.error('Error:', error.message);
            setStatusToast({ status: 'error', message: `Error ${selectedAction.charAt(0).toUpperCase() + selectedAction.slice(1)} ` });
            setLoading(false);
        }
    };
    const handleSelectAllChange = () => {
        setSelectAll(prevSelectAll => {
            const newSelectAll = !prevSelectAll;
            setSelectedProductIds(newSelectAll ? currentItems.map(item => item.id) : []);
            return newSelectAll;
        });
    };
    

    // const handleCheckbox1Change = (productId) => {
    //     setSelectedProductIds((prevSelectedIds) => {
    //         if (prevSelectedIds.includes(productId)) {
    //             return prevSelectedIds.filter((id) => id !== productId);
    //         } else {
    //             return [...prevSelectedIds, productId];
    //         }
    //     });
    // };
    const handleCheckbox1Change = (productId) => {
        setSelectedProductIds((prevSelectedIds) => {
            const newSelectedIds = prevSelectedIds.includes(productId) 
                ? prevSelectedIds.filter((id) => id !== productId)
                : [...prevSelectedIds, productId];
    
            if (newSelectedIds.length === currentItems.length) {
                setSelectAll(true);
            } else {
                setSelectAll(false);
            }
    
            return newSelectedIds;
        });
    };
    
    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);

    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };


    const handleFilterClick = async () => {
        try {
            setLoading(true);
            const result = await fetchAllProduct(fromDate, toDate, selectedConditions, selectedStatus, selectedCategory, selectedLocation);
            setAllproductsData(result);
            setSelectedLocation('');
            setSelectedCategory('');
            setSelectedCondition('');
            setSelectedStatus('');
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setStatusToast({ status: 'error', message: 'Error fetching products' });
        }
    };

    const handleActivateClick = async (productid, status) => {
        try {
            setLoading(true);
            const newStatus = status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE';
            const paylaod = {
                "itemId": productid,
                "status": newStatus

            };
            const res = await updateStautsButtom(paylaod);
            console.log(res);
            const updatedResult = await fetchAllProduct();
            setAllproductsData(updatedResult);
            setTimeout(async () => {
                setLoading(false);
            }, 500);
            if (newStatus === 'ACTIVE') {
                setStatusToast({ status: 'success', message: 'Product Activated successfully!' });
            } else if (newStatus === 'SUSPENDED') {
                setStatusToast({ status: 'success', message: 'Product Suspended successfully!' });
            }


        } catch (error) {
            console.error('Error updating product status:', error);
            setLoading(false);
            setStatusToast({ status: 'error', message: 'Error Updating product Status' });
        }
    };



    if (AllproductData === null || filteredList === null || loading || selectedAction === null) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }

    return (
        <div>
            <Header title={"Products"} />
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' }}>
                    <Spinner animation="border" variant="light" />
                </div>
            )}
            {/* Tabs */}
            <div className="mainButtons">
                <div style={{ display: "flex" }}>
                    <Dropdown>
                        <Dropdown.Toggle className="button1">
                            {selectedAction}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="productActions">
                            <Dropdown.Item className="actionSidebar" onClick={() => handleActionChange('BOOST')} >Boost</Dropdown.Item>
                            <Dropdown.Item className="actionSidebar" onClick={() => handleActionChange('EXPIRE')} >Expire</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className="button2" type="button" onClick={() => handleApplyClick(selectedAction)}> Apply </div>

                    <div className="searchBar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 22 21" fill="none">
                            <path d="M9.11865 17.0625C4.4374 17.0625 0.587402 13.2562 0.587402 8.53125C0.587402 3.85 4.39365 0 9.11865 0C13.8437 0 17.6499 3.80625 17.6499 8.53125C17.6062 13.2562 13.7999 17.0625 9.11865 17.0625ZM9.11865 1.26875C5.09365 1.26875 1.8124 4.55 1.8124 8.575C1.8124 12.6 5.09365 15.8812 9.11865 15.8812C13.1437 15.8812 16.4249 12.6 16.4249 8.575C16.3812 4.55 13.0999 1.26875 9.11865 1.26875Z" fill="#9A9A9A" />
                            <path d="M20.4937 20.8687L14.5437 14.9625L15.5062 14L21.4125 19.95L20.4937 20.8687Z" fill="#9A9A9A" />
                        </svg>
                        <input placeholder="Search Products" className="search" type="search" id="search" onChange={handleSearchChange} />
                    </div>
                    <div className="button3" type="button" onClick={() => setShowOverlay(true)}> Filter </div>
                    {showOverlay && (
                        <>
                            <div className="overlayBackground" onClick={() => setShowOverlay(false)}></div>
                            <div className="overlay">
                                <div style={{ width: "95%", backgroundColor: "#121212", marginLeft: "10px", padding: "15px", color: "#9A9A9A", border: "2px solid #252525", paddingTop: "25px", justifyContent: "center", alignSelf: "center" }}>
                                    <div className="filterTitle">Filter Products:</div>
                                    <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                                        <div className="formGroup">
                                            {/*
                                        <label htmlFor="categoryDropdown">Select Category:</label> */}
                                            <select className="filterChoices" id="categoryDropdown" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                                <option value="" disabled >Select Category</option>
                                                {categoriesData.map((category) => (
                                                    <option value={category.mainInfo.id} key={category.mainInfo.id}>{category.mainInfo.name} </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="formGroup">
                                            {/*
                                        <label htmlFor="categoryDropdown">Select Category:</label> */}
                                            <select className="filterChoices" id="LocationDropdown" value={selectedLocation} onChange={(e) => setSelectedLocation(e.target.value)}>
                                                <option value="" disabled >Select Location</option>
                                                {locationData.map((location) => (
                                                    <option value={location.id} key={location.id}>{location.address} </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="formGroup">
                                            {/* 
                                        <label htmlFor="conditionDropdown">Select Condition:</label> */}
                                            <select className="filterChoices" id="conditionDropdown" value={selectedConditions} onChange={(e) => setSelectedCondition(e.target.value)}>
                                                <option value="" disabled>Select Condition</option>
                                                <option value="FAIR">Fair</option>
                                                <option value="GOOD">Good</option>
                                                <option value="LIKENEW">Like New</option>
                                                <option value="NEW">New</option>
                                            </select>
                                        </div>

                                        <div className="formGroup">
                                            {/** 
                                        <label htmlFor="statusDropdown">Select Status:</label> */}
                                            <select className="filterChoices" id="statusDropdown" value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                                                <option value="" disabled >Select Status</option>
                                                <option value="SUSPENDED">Suspended</option>
                                                <option value="ACTIVE">Active</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="dateRange">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                            <path d="M18.3337 1.875H15.625V0.625C15.625 0.279375 15.3456 0 15 0C14.6544 0 14.375 0.279375 14.375 0.625V1.875H10.625V0.625C10.625 0.279375 10.345 0 10 0C9.655 0 9.375 0.279375 9.375 0.625V1.875H5.625V0.625C5.625 0.279375 5.345 0 5 0C4.655 0 4.375 0.279375 4.375 0.625V1.875H1.66688C0.74625 1.875 0 2.62063 0 3.54125V18.3331C0 19.2537 0.74625 20 1.66688 20H18.3337C19.2544 20 20 19.2537 20 18.3331V3.54125C20 2.62063 19.2544 1.875 18.3337 1.875ZM18.75 18.3331C18.75 18.5631 18.5631 18.75 18.3337 18.75H1.66688C1.43687 18.75 1.25 18.5631 1.25 18.3331V3.54125C1.25 3.31187 1.43687 3.125 1.66688 3.125H4.375V4.375C4.375 4.72062 4.655 5 5 5C5.345 5 5.625 4.72062 5.625 4.375V3.125H9.375V4.375C9.375 4.72062 9.655 5 10 5C10.345 5 10.625 4.72062 10.625 4.375V3.125H14.375V4.375C14.375 4.72062 14.6544 5 15 5C15.3456 5 15.625 4.72062 15.625 4.375V3.125H18.3337C18.5631 3.125 18.75 3.31187 18.75 3.54125V18.3331Z" fill="#9A9A9A" />
                                            <path d="M6.875 7.5H4.375V9.375H6.875V7.5Z" fill="#9A9A9A" />
                                            <path d="M6.875 10.625H4.375V12.5H6.875V10.625Z" fill="#9A9A9A" />
                                            <path d="M6.875 13.75H4.375V15.625H6.875V13.75Z" fill="#9A9A9A" />
                                            <path d="M11.25 13.75H8.75V15.625H11.25V13.75Z" fill="#9A9A9A" />
                                            <path d="M11.25 10.625H8.75V12.5H11.25V10.625Z" fill="#9A9A9A" />
                                            <path d="M11.25 7.5H8.75V9.375H11.25V7.5Z" fill="#9A9A9A" />
                                            <path d="M15.625 13.75H13.125V15.625H15.625V13.75Z" fill="#9A9A9A" />
                                            <path d="M15.625 10.625H13.125V12.5H15.625V10.625Z" fill="#9A9A9A" />
                                            <path d="M15.625 7.5H13.125V9.375H15.625V7.5Z" fill="#9A9A9A" />
                                        </svg>
                                        <div style={{ marginLeft: "5px" }}>Date Range:</div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="from">From</div>
                                        <input className="date" type="date" pattern="\d{4}-\d{2}-\d{2}" value={fromDate} onChange={handleFromDateChange} />
                                        <div className="from">To</div>
                                        <input className="date" type="date" pattern="\d{4}-\d{2}-\d{2}" value={toDate} onChange={handleToDateChange} />
                                    </div>
                                    <div className="button3" type="button" style={{ height: "40px", margin: "20px", marginLeft: "100px" }} onClick={() => { handleFilterClick(); setShowOverlay(false); }}>
                                        Filter
                                    </div>
                                </div>
                                <CloseIcon className="overlayClose" onClick={() => setShowOverlay(false)} type="button" />
                            </div>
                        </>
                    )}
                </div>

                <div style={{ display: "flex" }}>
                    <div className="viewIcon" type="button" onClick={() => setActiveTab('List')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 21 19" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.45935 0C0.653377 0 6.01232e-06 0.653371 6.01232e-06 1.45935V3.64837C6.01232e-06 4.45436 0.653377 5.10771 1.45935 5.10771H3.64837C4.45437 5.10771 5.10772 4.45436 5.10772 3.64837V1.45935C5.10772 0.653371 4.45437 0 3.64837 0H1.45935ZM1.45935 1.45935H3.64837V3.64837H1.45935V1.45935ZM7.29674 1.45935C6.89374 1.45935 6.56707 1.78604 6.56707 2.18902C6.56707 2.592 6.89374 2.91869 7.29674 2.91869H10.9451C11.3481 2.91869 11.6748 2.592 11.6748 2.18902C11.6748 1.78604 11.3481 1.45935 10.9451 1.45935H7.29674ZM7.29674 8.02641C6.89374 8.02641 6.56707 8.35308 6.56707 8.75608C6.56707 9.15908 6.89374 9.48575 7.29674 9.48575H10.9451C11.3481 9.48575 11.6748 9.15908 11.6748 8.75608C11.6748 8.35308 11.3481 8.02641 10.9451 8.02641H7.29674ZM6.01232e-06 8.02641C6.01232e-06 7.22041 0.653377 6.56706 1.45935 6.56706H3.64837C4.45437 6.56706 5.10772 7.22041 5.10772 8.02641V10.2154C5.10772 11.0214 4.45437 11.6748 3.64837 11.6748H1.45935C0.653377 11.6748 6.01232e-06 11.0214 6.01232e-06 10.2154V8.02641ZM3.64837 8.02641H1.45935V10.2154H3.64837V8.02641Z" fill="#9A9A9A" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.45935 6.56706C0.653377 6.56706 6.01232e-06 7.22041 6.01232e-06 8.02641V10.2154C6.01232e-06 11.0214 0.653377 11.6748 1.45935 11.6748H3.64837C4.45437 11.6748 5.10772 11.0214 5.10772 10.2154V8.02641C5.10772 7.22041 4.45437 6.56706 3.64837 6.56706H1.45935ZM1.45935 8.02641H3.64837V10.2154H1.45935V8.02641ZM7.29674 8.02641C6.89374 8.02641 6.56707 8.35308 6.56707 8.75608C6.56707 9.15908 6.89374 9.48575 7.29674 9.48575H10.9451C11.3481 9.48575 11.6748 9.15908 11.6748 8.75608C11.6748 8.35308 11.3481 8.02641 10.9451 8.02641H7.29674ZM7.29673 14.5935C6.89373 14.5935 6.56706 14.9201 6.56706 15.3231C6.56706 15.7261 6.89373 16.0528 7.29673 16.0528H10.9451C11.3481 16.0528 11.6748 15.7261 11.6748 15.3231C11.6748 14.9201 11.3481 14.5935 10.9451 14.5935H7.29673ZM-2.00411e-06 14.5935C-2.00411e-06 13.7875 0.653369 13.1341 1.45934 13.1341H3.64836C4.45436 13.1341 5.10771 13.7875 5.10771 14.5935V16.7825C5.10771 17.5885 4.45436 18.2418 3.64836 18.2418H1.45934C0.653369 18.2418 -2.00411e-06 17.5885 -2.00411e-06 16.7825V14.5935ZM3.64836 14.5935H1.45934V16.7825H3.64836V14.5935Z" fill="#9A9A9A" />
                            <rect x="6.55225" y="1.44482" width="14.4475" height="1.48853" rx="0.744267" fill="#9A9A9A" />
                            <rect x="6.55225" y="8.01196" width="14.4475" height="1.48853" rx="0.744267" fill="#9A9A9A" />
                            <rect x="6.55225" y="14.5789" width="14.4475" height="1.48853" rx="0.744267" fill="#9A9A9A" />
                        </svg>
                    </div>
                    <div className="viewIcon" type="button" onClick={() => setActiveTab('Grid')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 22 22" fill="none">
                            <path d="M5.16667 1C1.98039 1 1 1.98039 1 5.16667C1 8.35294 1.98039 9.33333 5.16667 9.33333C8.35294 9.33333 9.33333 8.35294 9.33333 5.16667C9.33333 1.98039 8.35294 1 5.16667 1Z" stroke="#9A9A9A" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.16667 12.6667C1.98039 12.6667 1 13.6472 1 16.8334C1 20.0196 1.98039 21.0001 5.16667 21.0001C8.35294 21.0001 9.33333 20.0196 9.33333 16.8334C9.33333 13.6472 8.35294 12.6667 5.16667 12.6667Z" stroke="#9A9A9A" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16.8334 12.6667C13.6472 12.6667 12.6667 13.6472 12.6667 16.8334C12.6667 20.0196 13.6472 21.0001 16.8334 21.0001C20.0196 21.0001 21.0001 20.0196 21.0001 16.8334C21.0001 13.6472 20.0196 12.6667 16.8334 12.6667Z" stroke="#9A9A9A" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16.8334 1C13.6472 1 12.6667 1.98039 12.6667 5.16667C12.6667 8.35294 13.6472 9.33333 16.8334 9.33333C20.0196 9.33333 21.0001 8.35294 21.0001 5.16667C21.0001 1.98039 20.0196 1 16.8334 1Z" stroke="#9A9A9A" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div style={{ width: "97%", marginLeft: "20px", display: "flex" }}>


                <div style={{ height: "100%", width: "100%", justifyContent: "left", display: "flex", border: "2px solid #252525" }}>

                    {activeTab === 'List' && <div style={{ width: "100%" }}>
                        {currentItems ? (
                            <div>
                                <table className="table" style={{ margin: "0px", width: "100%" }}>
                                    <thead className="tableHeading">
                                        <tr>
                                            <th className="column" style={{ paddingLeft: "15px" }}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="flexCheckDefault"
                                                    checked={selectAll}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th className="column">Product Id</th>
                                            <th className="column">Product Title </th>
                                            <th className="column">Date Added</th>
                                            <th className="column">No. of View</th>
                                            <th className="column">No. of Likes</th>
                                            <th className="column">Bids</th>
                                            <th className="column">Location</th>
                                            <th className="column">Status</th>
                                            <th className="column">Boost End Date</th>
                                            <th className="column"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems.map((product) => (
                                            <tr
                                                key={product.id}
                                                style={{ borderBottom: "#121212", backgroundColor: "#030303" }}
                                            >
                                                <td style={{ marginLeft: "20px", fontSize: "12px", height: "30px", paddingLeft: "15px" }}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault"
                                                        checked={selectedProductIds.includes(product.id)}
                                                        onChange={() => handleCheckbox1Change(product.id)}

                                                    />
                                                </td>
                                                <td style={{ paddingTop: "8px", height: "30px" }}>{product.productId}</td>
                                                <td>{product.title}</td>
                                                <td>{product.createdAt}</td>
                                                <td>{product.views}</td>
                                                <td>{product.likes}</td>
                                                <td>{product.bids}</td>
                                                <td>{product.locationAddress}</td>
                                                <td>
                                                    <div className={`accountStatus${product.status === 'ACTIVE' ? 'Active2' : 'Suspended2'}`}>
                                                        {product.status}
                                                    </div>
                                                </td>
                                                <td>{product.boostEndDate || 'None'}</td>
                                                <td style={{ height: "30px", display: "flex" }}>
                                                    {product.status === 'ACTIVE' ? (
                                                        <div
                                                            type="button"
                                                            className="suspendButton"
                                                            onClick={() => handleActivateClick(product.id, product.status)}
                                                        >
                                                            Suspend
                                                        </div>
                                                    ) : (
                                                        <div
                                                            type="button"
                                                            className="activateButton"
                                                            onClick={() => handleActivateClick(product.id, product.status)}
                                                        >
                                                            Activate
                                                        </div>
                                                    )}
                                                    <Link to={`/productDetails/${product.id}`} style={{ textDecoration: "none" }}>
                                                        <div style={{ width: "20px", height: "20px" }}>
                                                            <img
                                                                src={Eye}
                                                                alt="eye"
                                                                style={{ width: "20px", height: "20px", margin: "0px 0 0px 10px" }}
                                                                type="button"
                                                            />
                                                        </div>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>)
                            : (
                                <>
                                    <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
                                        <Spinner animation="border" variant="light" />
                                    </div>
                                </>
                            )}

                        <div>

                        </div>
                    </div>
                    }
                    {activeTab === 'Grid' &&
                        <div style={{ width: "80vw", display: "flex", flexWrap: "wrap" }} >
                            {currentItems.map((product) => (
                                <Link to={`/productDetails/${product.id}`} style={{ textDecoration: "none" }}>
                                <div className="card" key={product.productId} style={{ width: "200px", backgroundColor: "#252525", margin: "20px 30px 30px 50px" }}>
                                    {product.images && product.images.length > 0 && !imageLoadError ? (
                                        <img style={{ width: "80%", margin: "20px 0 0 20px", height: "160px" }} src={product.images[0]} alt="..." onError={handleImageError} />
                                    ) : (
                                        <p style={{ color: 'white', textAlign: 'center', display: "flex", justifyContent: "center", marginTop: "20px", alignItems: "center", height: "100px" }}>Image failed to load. Retrying...</p>
                                    )}

                                    <div className="card-img-overlay">
                                        <div className={`accountStatus${product.status === 'ACTIVE' ? 'Active2' : 'Suspended2'}`} style={{ width: 'fit-content', height: 'fit-content', color: "white", fontSize: "10px", padding: "2px 5px 2px 5px", fontFamily: 'Montserrat', fontWeight: '500', margin: "6px" }}> {product.status} </div>
                                    </div>

                                    <div>
                                        <p className="card-text" style={{ color: "white", marginLeft: "20px", width: "fit-content", marginTop: "15px", marginBottom: "20px" }}>{product.productId}
                                            <p className="card-text" style={{ color: '#A0A0A0', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', display: "block" }}> {product.createdAt} </p>
                                        </p>

                                        <div style={{ display: "flex", width: "88%", justifyContent: "space-between", marginLeft: "10px", alignItems: "center" }}>
                                            <div className="likes1" style={{ display: "flex" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" style={{ marginTop: "2px" }}>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.43184 7.11462L8.11463 1.43184C8.31021 1.23629 8.62727 1.23629 8.82285 1.43185L11.9799 4.58896C12.1755 4.78452 12.1755 5.10157 11.9799 5.29712L6.29712 10.9799C6.10157 11.1755 5.78451 11.1755 5.58896 10.9799L2.43184 7.82283C2.23629 7.62726 2.23629 7.31019 2.43184 7.11462ZM1.52308 8.73161C0.825639 8.03413 0.825639 6.90332 1.52308 6.20592L7.20586 0.523084C7.90334 -0.174361 9.03414 -0.174361 9.73155 0.523084L12.8887 3.6802C13.5861 4.37765 13.5861 5.50844 12.8887 6.20592L11.7608 7.33371L14.2902 9.8631C15.2366 10.8094 15.2366 12.3438 14.2902 13.2901C13.3438 14.2365 11.8095 14.2365 10.8631 13.2901L8.3338 10.7608L7.20586 11.8887C6.50844 12.5862 5.37764 12.5861 4.6802 11.8887L1.52308 8.73161ZM9.2425 9.85206L11.7719 12.3814C12.2163 12.8259 12.937 12.8259 13.3815 12.3814C13.8259 11.9369 13.8259 11.2163 13.3815 10.7719L10.8521 8.24248L9.2425 9.85206Z" fill="#9A9A9A" />
                                                    <path d="M1.15308 14.4407H4.72493" stroke="#9A9A9A" strokeLinecap="round" />
                                                </svg>
                                                <p style={{ marginLeft: "5px", color: "#9A9A9A" }}>{product.likes} </p>
                                            </div>
                                            <div className="likes2" style={{ display: "flex" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 14 13" fill="none" style={{ marginTop: "4px" }}>
                                                    <path d="M7 1.93158L6.64811 2.27025C6.74019 2.36591 6.86723 2.41996 7 2.41996C7.13277 2.41996 7.25981 2.36591 7.35189 2.27025L7 1.93158ZM5.32394 10.2803C4.337 9.50231 3.25781 8.74253 2.40168 7.77849C1.56228 6.83326 0.976744 5.73038 0.976744 4.29952H0C0 6.03519 0.723116 7.35933 1.67135 8.42704C2.60285 9.47594 3.79027 10.315 4.71925 11.0474L5.32394 10.2803ZM0.976744 4.29952C0.976744 2.89895 1.76815 1.72444 2.84842 1.23065C3.89791 0.750928 5.30806 0.87797 6.64811 2.27025L7.35189 1.59291C5.76181 -0.0590921 3.91615 -0.331363 2.44236 0.342311C0.999346 1.00191 0 2.53352 0 4.29952H0.976744ZM4.71925 11.0474C5.0528 11.3103 5.41086 11.5907 5.77373 11.8027C6.13649 12.0147 6.55044 12.187 7 12.187V11.2102C6.7984 11.2102 6.56118 11.1316 6.26653 10.9594C5.97201 10.7873 5.66647 10.5504 5.32394 10.2803L4.71925 11.0474ZM9.28076 11.0474C10.2097 10.315 11.3972 9.47594 12.3287 8.42704C13.2769 7.35933 14 6.03519 14 4.29952H13.0233C13.0233 5.73038 12.4377 6.83326 11.5983 7.77849C10.7422 8.74253 9.663 9.50231 8.67609 10.2803L9.28076 11.0474ZM14 4.29952C14 2.53352 13.0007 1.00191 11.5576 0.342311C10.0838 -0.331363 8.23819 -0.0590921 6.64811 1.59291L7.35189 2.27025C8.69192 0.87797 10.1021 0.750928 11.1516 1.23065C12.2318 1.72444 13.0233 2.89895 13.0233 4.29952H14ZM8.67609 10.2803C8.33352 10.5504 8.02799 10.7873 7.73347 10.9594C7.43882 11.1316 7.2016 11.2102 7 11.2102V12.187C7.44956 12.187 7.86351 12.0147 8.22627 11.8027C8.58916 11.5907 8.94717 11.3103 9.28076 11.0474L8.67609 10.2803Z" fill="#9A9A9A" />
                                                </svg>
                                                <p style={{ marginLeft: "5px", color: "#9A9A9A" }}>{product.views}</p>
                                            </div>
                                            <div className="likes3" style={{ display: "flex" }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 16 11" fill="none" style={{ marginTop: "4px" }}>
                                                    <path d="M8.00005 9.94444C9.5035 9.94444 10.7223 8.72566 10.7223 7.22222C10.7223 5.71878 9.5035 4.5 8.00005 4.5C6.49661 4.5 5.27783 5.71878 5.27783 7.22222C5.27783 8.72566 6.49661 9.94444 8.00005 9.94444Z" stroke="#A0A0A0" />
                                                    <path d="M15 7.22222C15 7.22222 14.2222 1 8 1C1.77778 1 1 7.22222 1 7.22222" stroke="#A0A0A0" />
                                                </svg>
                                                <p style={{ marginLeft: "5px", color: "#9A9A9A" }}>{product.bids}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            ))}

                        </div>
                    }
                </div>
            </div>
            {statusToast && (
                <Toast onClose={() => setStatusToast(null)} autohide className="toast">
                    <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                        <div >
                            {statusToast.status === 'success' ? 'Success' : 'Error'}
                        </div>
                    </Toast.Header>
                    <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                </Toast>
            )}
            <Pagination className="paging">{items}</Pagination>
        </div>

    );
};

export default Products;