// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topDiv {
    height: 35px;
    margin: 20px;
    width: 86vw;
    display: flex;
    justify-content: space-between;
}

.viewAll {
    width: 95px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 3px;
    border: 1px solid #030303;
    background: #030303;
    color: #B3B3B3;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
    text-transform: capitalize;
}

.titleDiv {
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/statistics/statDashboard/statisticsDashboard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".topDiv {\n    height: 35px;\n    margin: 20px;\n    width: 86vw;\n    display: flex;\n    justify-content: space-between;\n}\n\n.viewAll {\n    width: 95px;\n    height: 35px;\n    flex-shrink: 0;\n    border-radius: 3px;\n    border: 1px solid #030303;\n    background: #030303;\n    color: #B3B3B3;\n    text-align: center;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 24px;\n    /* 171.429% */\n    text-transform: capitalize;\n}\n\n.titleDiv {\n    color: #FFF;\n    font-family: Montserrat;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
