import Header from "../../global/Header";
import { ProgressBar, Spinner } from "react-bootstrap";
import { fetchBehaviourStat } from "../../global/axios";
import { useState, useEffect, useCallback } from "react";
import { Toast } from "react-bootstrap";

const Behaviour = () => {
    const [behaviourStats, setBehaviourStats] = useState(null);
    const [statusToast, setStatusToast] = useState(null);

    const fetchData = useCallback(async () => {
        try {
          await new Promise((resolve) => setTimeout(resolve, 500));
          const data = await fetchBehaviourStat();
          setBehaviourStats(data);
        } catch (error) {
          console.error("Error fetching behaviour statistics:", error);
          setStatusToast({ status: 'error', message: 'Error fetching behaviour statistics' });
        }
      }, []);

      useEffect(() => {
        fetchData();
      }, [fetchData]);
      
      if (behaviourStats === null) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }
    

    return (
        <div>
            <Header title={"User Behaviour"} />
            <div style={{ display: "flex", marginLeft: "20px"}}>
                <div style={{ height: "240px", marginBottom:"20px"  }}>
                    <div className="chart" style={{ marginLeft: "0px", width: "300px", marginTop: "18px" }}>
                        <div className="chartTitle" style={{ paddingBottom: "10px" }}>
                            Most Used Categories
                        </div>
                        <div>
                        {behaviourStats.mostUsedCategories.slice(0, 5).map((category) => (
                                <div  style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="barLabel">{category.categoryName}</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar className="progressBar" now={category.percentage} max="100" />
                                    </div>
                                    <div className="barValue">{category.percentage.toFixed(2)}%</div>
                                </div>
                            ))}    
                        </div>
                    </div>
                </div>
                <div style={{ height: "240px", marginBottom:"20px"  }}>
                    <div className="chart" style={{ marginLeft: "0px", width: "300px", marginTop: "18px" }}>
                        <div className="chartTitle" style={{ paddingBottom: "10px" }}>
                            Top Interest
                        </div>
                        <div>
                            {behaviourStats.topInterests.slice(0, 5).map((interest) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="barLabel">{interest.interest}</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar className="progressBar" now={interest.percentage} max="100" />
                                    </div>
                                    <div className="barValue">{interest.percentage.toFixed(2)}%</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="boxes">
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }} >
                    <div className="boxTitle" style={{ color: "white" }}>
                        {(behaviourStats?.swipeRatio?.leftCount || 0)}:{(behaviourStats?.swipeRatio?.rightCount || 0)}
                    </div>

                        <div className="boxSubtitle" style={{ color: "white" }}>Swipe Right to Left Ratio</div>
                    </div>
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }} >
                        <div className="boxTitle" style={{ color: "white" }}>{behaviourStats.countOfSuccessfulSwapRequests}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Total Accepted Swaps this month</div>
                    </div>
                </div>
            </div>
            {statusToast && (
                    <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                        <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            <div >
                                {statusToast.status === 'success' ? 'Success' : 'Error'}
                            </div>
                        </Toast.Header>
                        <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                    </Toast>
                )}
        </div>
    );
};

export default Behaviour;