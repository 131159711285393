// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th{
    background: #121212 !important;
    color: #FFFFFF !important;
    padding-bottom: 18px !important;
}

.tableHeading{
    height: 50px;
    width: 100%;
}

td{
    background-color: #121212 !important;
    color: #FFFFFF !important;
    border: none !important; 
    align-items: center;
    justify-content: center;
}

.accountStatusActive2{
    border-radius: 16px;
    background: #3CCC65;
    font-size: 13px;
    width: 98px;
    height: 26px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    font-family: "Montserrat";
}

.accountStatusSuspended2{
    border-radius: 16px;
    background: #D43A2F;
    font-size: 12px;
    width: 98px;
    height: 26px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    color: #FFF;
    font-Family: Montserrat;
}`, "",{"version":3,"sources":["webpack://./src/pages/products/list.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,+BAA+B;AACnC;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":["th{\n    background: #121212 !important;\n    color: #FFFFFF !important;\n    padding-bottom: 18px !important;\n}\n\n.tableHeading{\n    height: 50px;\n    width: 100%;\n}\n\ntd{\n    background-color: #121212 !important;\n    color: #FFFFFF !important;\n    border: none !important; \n    align-items: center;\n    justify-content: center;\n}\n\n.accountStatusActive2{\n    border-radius: 16px;\n    background: #3CCC65;\n    font-size: 13px;\n    width: 98px;\n    height: 26px;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    flex-shrink: 0;\n    font-family: \"Montserrat\";\n}\n\n.accountStatusSuspended2{\n    border-radius: 16px;\n    background: #D43A2F;\n    font-size: 12px;\n    width: 98px;\n    height: 26px;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    flex-shrink: 0;\n    color: #FFF;\n    font-Family: Montserrat;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
