import Header from "../global/Header";
import "../interests/interest.css";
import { useState, useCallback, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../products/CloseIcon.svg";
import { fetchInterest, createIntrest, Updateinterst } from "../global/axios";
import { Spinner, Pagination, Toast  } from "react-bootstrap";
import "../globalItems/ToastMessage/toastMessage.css";

const Interest = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [showOverlay2, setShowOverlay2] = useState(false);
    const [interestData, setInterestData] = useState(null);
    const [Name, setName] = useState('');
    const [selectedInterestId, setSelectedInterestId] = useState(null);
    const [UName, setUName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const [statusToast, setStatusToast] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            const data = await fetchInterest();
            setInterestData(data);
        } catch (error) {
            console.error("Error fetching interest data:", error);
            setStatusToast({ status: 'error', message: 'Error fetching interest data' });
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    const HandlecretionIntrest = async () => {
        try {
            await createIntrest(Name)
            setStatusToast({ status: 'success', message: 'Interest created successfully!' });
            fetchData();
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setStatusToast({ status: 'error', message: 'Interest already exist with the same name' });
            }
        }

    };
    const UpdateIntrest = async (id) => {
        try {
            await Updateinterst(id, UName)
            setStatusToast({ status: 'success', message: 'Interest updated successfully!' });
            fetchData();

        } catch (error) {
            if (error.response && error.response.status === 500) {
                setStatusToast({ status: 'error', message: 'Interest already exist with the same name' });
            }
        }

    };
    const handleInputChange = (e) => {
        setUName(e.target.value);
    };
    const InterestUpd = (name) => {
        setUName(name);
    };
    let totalPages, currentItems;
    if (interestData) {
        totalPages = Math.ceil(interestData.length / itemsPerPage);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        currentItems = interestData.slice(indexOfFirstItem, indexOfLastItem);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>
        );
    }

    if (interestData === null) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }

    return (

        <div>
            <Header title={"Interests"} />
            <div className="createButtonHeader">
                <div className="createButton" type="button" onClick={() => setShowOverlay2(true)}>Create</div>
                {showOverlay2 && (
                    <>
                        <div className="overlayBackground" onClick={() => setShowOverlay2(false)}></div>
                        <div className="overlay">
                            <div className="createOverlay2">
                                <div className="createOverlayTitle">Create A New Interest:</div>
                                <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                    <div className="entryTitle">Name:</div>
                                    <input className="entryInput" onChange={(e) => setName(e.target.value)}></input>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                    <div className="submitCreation" type="button" onClick={() => { setShowOverlay2(false); HandlecretionIntrest(); }}> Submit </div>
                                </div>
                            </div>
                            <CloseIcon className="overlayClose" onClick={() => setShowOverlay2(false)} type="button" />
                        </div>
                    </>
                )}

            </div>
            <div style={{ width: "87vw" }}>
                <table className="interestTable">
                    <thead className="interestTableHeading">
                        <tr>
                            <th className="interestTableHead2">ID</th>
                            <th className="interestTableHead">Name</th>
                            <th className="interestTableHead"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((interest) => (
                            <tr key={interest.id} className="interestTableRow">
                                <td className="interestTableData4">{interest.id}</td>
                                <td className="interestTableData">{interest.name}</td>
                                <td className="interestTableData3"><div className="updateButton" type="button" onClick={() => { setSelectedInterestId(interest.id); setShowOverlay(true); InterestUpd(interest.name); }}>Update</div></td>
                                {showOverlay && selectedInterestId === interest.id && (
                                    <>
                                        <div className="overlayBackground" onClick={() => setShowOverlay(false)}></div>
                                        <div className="overlay">
                                            <div className="createOverlay2">
                                                <div className="createOverlayTitle">Update Interest:</div>
                                                <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                                                    <div className="entryTitle" > Name:</div>
                                                    <input className="entryInput" value={UName} onChange={handleInputChange}></input>
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                                    <div className="submitCreation" type="button" onClick={() => { UpdateIntrest(interest.id); setShowOverlay(false) }}> Submit </div>
                                                </div>
                                            </div>
                                            <CloseIcon className="overlayClose" onClick={() => setShowOverlay(false)} type="button" />
                                        </div>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div>

                <Pagination className="paging">{items}</Pagination>
                {statusToast && (
                    <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                        <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            <div >
                                {statusToast.status === 'success' ? 'Success' : 'Error'}
                            </div>
                        </Toast.Header>
                        <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                    </Toast>
                )}
            </div>
        </div>
    );
};

export default Interest;