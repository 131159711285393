import "../middleSection/generalInformation.css";

const GeneralInfo = ({ user }) => {
    if (!user) {
        return <div style={{color:"white", display:"flex", justifyContent:"center", alignItems:"center"}}>...</div>;
      }
      const {
        id,
        name,
        gender,
        dob,
        registerMethod,
        joinedAt,
        phone,
        email,
        meetUpLocation,
        bio,
        
      } = user;
    return (
        <div className="main" key={id}>
            {/* Personal Information */}
            <div className="generalTitle">Personal Information</div>

            <div style={{display:"flex", width:"100%", height:"fit-content"}}>
                <div className="generalSubtitle">Name</div>
                <div className="generalSubtitle">Gender</div>
                <div className="generalSubtitle">Date of Birth</div>
            </div>

            <div style={{display:"flex", width:"100%", height:"fit-content", marginTop:"5px"}}>
                <div className="generalContent">{name}</div>
                <div className="generalContent">{gender}</div>
                <div className="generalContent">{dob}</div>
            </div>

            <div style={{display:"flex", width:"100%", height:"fit-content", marginTop:"18px"}}>
                <div className="generalSubtitle">Registered by</div>
                <div className="generalSubtitle">Joined at</div>
            </div>

            <div style={{display:"flex", width:"100%", height:"fit-content", marginTop:"5px"}}>
                <div className="generalContent">{registerMethod}</div>
                <div className="generalContent">{joinedAt}</div>
            </div>

            {/* Contact Information */}
            <div className="generalTitle" style={{marginTop:"30px"}}>Contact Information</div>

            <div style={{display:"flex", width:"100%", height:"fit-content"}}>
                <div className="generalSubtitle">Mobile Number</div>
                <div className="generalSubtitle">Email</div>
            </div>

            <div style={{display:"flex", width:"100%", height:"fit-content", marginTop:"5px"}}>
                <div className="generalContent">{phone}</div>
                <div className="generalContent">{email}</div>
            </div>

            {/* Location Information */}
            <div className="generalTitle" style={{marginTop:"30px"}}>Meet-Up Location</div>

            <div style={{display:"flex", width:"100%", height:"fit-content"}}>
                <div className="generalSubtitle">Address</div>
                <div className="generalSubtitle">City</div>
                <div className="generalSubtitle">Country</div>
            </div>

            <div style={{display:"flex", width:"100%", height:"fit-content", marginTop:"5px"}}>
                <div className="generalContent">{meetUpLocation.address}</div>
                <div className="generalContent">{meetUpLocation.city}</div>
                <div className="generalContent">{meetUpLocation.country}</div>
            </div>
            {/* Account Notes */}
            <div className="generalTitle" style={{marginTop:"30px"}}>Account Notes</div>
            <div className="generalContent" style={{width:"100%", height:"fit-content"}}>{bio}</div>
        </div>
);
};

export default GeneralInfo;