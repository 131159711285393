import { fetchUserproduct } from "../../../global/axios";
import { useEffect, useState } from 'react';
import { Pagination } from "react-bootstrap";


const UserProducts = (user) => {
    const [productData, setproductsData] = useState([]);
    const [imageLoadError, setImageLoadError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3);
    const [loading, setLoading] = useState(true);
    const handleImageError = () => {
        setImageLoadError(true);
    };
    useEffect(() => {
        const fetchproducts = async (user) => {
            try {
                
                await new Promise((resolve) => setTimeout(resolve, 1000));
                const products = await fetchUserproduct(user);
                setproductsData(products.data);
                console.log("hi from product data",products.data)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user products:', error);
            }
        };
        if (user && user.user)  {
            //console.log(user.user);
            fetchproducts(user.user);
        }
    }, [user]);

    let totalPages, currentItems;
    if (productData) {
        totalPages = Math.ceil(productData.length / itemsPerPage);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        currentItems = productData.slice(indexOfFirstItem, indexOfLastItem);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>
        );
    }
   
     if (productData === null || loading) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            {/* <Spinner animation="border" variant="light" /> */}
            <div>No User selected</div>
        </div>;
    }
    else if (productData.length === 0) {
        return <div style={{ color: "white", marginLeft:"20px" }}> No products to be shown...</div>;
    }
    return (
        <div style={{ width: "100%" }}>
            <div style={{ display: "flex", width: "100%" }}>
                {currentItems.map((product) => (
                    <div className="card" key={product.productId} style={{ width: "200px", backgroundColor: "#252525", margin: "20px 20px 20px 60px" }}>
                        {product.images && product.images.length > 0 && !imageLoadError ? (
                            <img style={{ width: "80%", margin: "20px 0 0 20px", height: "160px" }} src={product.images[0]} alt="..." onError={handleImageError} />
                        ) : (
                            <p style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>Image failed to load. Retrying...</p>
                        )}

                        <div className="card-img-overlay">
                            <div style={{ width: 'fit-content', height: 'fit-content', background: '#121212', borderRadius: 4, border: '1px #121212 solid', color: "white", fontSize: "10px", padding: "2px 5px 2px 5px", fontFamily: 'Montserrat', fontWeight: '500', margin: "6px" }}> {product.status} </div>
                        </div>
                        <div>
                            <div className="card-text" style={{ color: "white", marginLeft: "20px", width: "fit-content", marginTop: "15px", marginBottom: "20px" }}>{product.productId}
                                <p className="card-text" style={{ color: '#A0A0A0', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', display: "block" }}> {product.createdAt} </p>
                            </div>

                            <div style={{ display: "flex", width: "88%", justifyContent: "space-between", marginLeft: "10px", alignItems: "center" }}>
                                <div className="likes1" style={{ display: "flex" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" style={{ marginTop: "2px" }}>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.43184 7.11462L8.11463 1.43184C8.31021 1.23629 8.62727 1.23629 8.82285 1.43185L11.9799 4.58896C12.1755 4.78452 12.1755 5.10157 11.9799 5.29712L6.29712 10.9799C6.10157 11.1755 5.78451 11.1755 5.58896 10.9799L2.43184 7.82283C2.23629 7.62726 2.23629 7.31019 2.43184 7.11462ZM1.52308 8.73161C0.825639 8.03413 0.825639 6.90332 1.52308 6.20592L7.20586 0.523084C7.90334 -0.174361 9.03414 -0.174361 9.73155 0.523084L12.8887 3.6802C13.5861 4.37765 13.5861 5.50844 12.8887 6.20592L11.7608 7.33371L14.2902 9.8631C15.2366 10.8094 15.2366 12.3438 14.2902 13.2901C13.3438 14.2365 11.8095 14.2365 10.8631 13.2901L8.3338 10.7608L7.20586 11.8887C6.50844 12.5862 5.37764 12.5861 4.6802 11.8887L1.52308 8.73161ZM9.2425 9.85206L11.7719 12.3814C12.2163 12.8259 12.937 12.8259 13.3815 12.3814C13.8259 11.9369 13.8259 11.2163 13.3815 10.7719L10.8521 8.24248L9.2425 9.85206Z" fill="#9A9A9A" />
                                        <path d="M1.15308 14.4407H4.72493" stroke="#9A9A9A" strokeLinecap="round" />
                                    </svg>
                                    <p style={{ marginLeft: "5px", color: "#9A9A9A" }}>{product.likes} </p>
                                </div>
                                <div className="likes2" style={{ display: "flex" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 14 13" fill="none" style={{ marginTop: "4px" }}>
                                        <path d="M7 1.93158L6.64811 2.27025C6.74019 2.36591 6.86723 2.41996 7 2.41996C7.13277 2.41996 7.25981 2.36591 7.35189 2.27025L7 1.93158ZM5.32394 10.2803C4.337 9.50231 3.25781 8.74253 2.40168 7.77849C1.56228 6.83326 0.976744 5.73038 0.976744 4.29952H0C0 6.03519 0.723116 7.35933 1.67135 8.42704C2.60285 9.47594 3.79027 10.315 4.71925 11.0474L5.32394 10.2803ZM0.976744 4.29952C0.976744 2.89895 1.76815 1.72444 2.84842 1.23065C3.89791 0.750928 5.30806 0.87797 6.64811 2.27025L7.35189 1.59291C5.76181 -0.0590921 3.91615 -0.331363 2.44236 0.342311C0.999346 1.00191 0 2.53352 0 4.29952H0.976744ZM4.71925 11.0474C5.0528 11.3103 5.41086 11.5907 5.77373 11.8027C6.13649 12.0147 6.55044 12.187 7 12.187V11.2102C6.7984 11.2102 6.56118 11.1316 6.26653 10.9594C5.97201 10.7873 5.66647 10.5504 5.32394 10.2803L4.71925 11.0474ZM9.28076 11.0474C10.2097 10.315 11.3972 9.47594 12.3287 8.42704C13.2769 7.35933 14 6.03519 14 4.29952H13.0233C13.0233 5.73038 12.4377 6.83326 11.5983 7.77849C10.7422 8.74253 9.663 9.50231 8.67609 10.2803L9.28076 11.0474ZM14 4.29952C14 2.53352 13.0007 1.00191 11.5576 0.342311C10.0838 -0.331363 8.23819 -0.0590921 6.64811 1.59291L7.35189 2.27025C8.69192 0.87797 10.1021 0.750928 11.1516 1.23065C12.2318 1.72444 13.0233 2.89895 13.0233 4.29952H14ZM8.67609 10.2803C8.33352 10.5504 8.02799 10.7873 7.73347 10.9594C7.43882 11.1316 7.2016 11.2102 7 11.2102V12.187C7.44956 12.187 7.86351 12.0147 8.22627 11.8027C8.58916 11.5907 8.94717 11.3103 9.28076 11.0474L8.67609 10.2803Z" fill="#9A9A9A" />
                                    </svg>
                                    <p style={{ marginLeft: "5px", color: "#9A9A9A" }}>{product.views}</p>
                                </div>
                                <div className="likes3" style={{ display: "flex" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 16 11" fill="none" style={{ marginTop: "4px" }}>
                                        <path d="M8.00005 9.94444C9.5035 9.94444 10.7223 8.72566 10.7223 7.22222C10.7223 5.71878 9.5035 4.5 8.00005 4.5C6.49661 4.5 5.27783 5.71878 5.27783 7.22222C5.27783 8.72566 6.49661 9.94444 8.00005 9.94444Z" stroke="#A0A0A0" />
                                        <path d="M15 7.22222C15 7.22222 14.2222 1 8 1C1.77778 1 1 7.22222 1 7.22222" stroke="#A0A0A0" />
                                    </svg>
                                    <p style={{ marginLeft: "5px", color: "#9A9A9A" }}>{product.bids}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Pagination className="paging">{items}</Pagination>
        </div>
    );
};

export default UserProducts;