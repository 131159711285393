// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.app,

.app {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif, 'Montserrat';
  margin: 0px;
  background-color:#121212;
}


/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */

::-webkit-scrollbar-thumb{
  background: #888;
}

/* Handle on Hover */

::-webkit-scrollbar-track:hover {
  background: #555;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;;;EAME,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,sDAAsD;EACtD,WAAW;EACX,wBAAwB;AAC1B;;;AAGA,UAAU;AACV;EACE,iBAAiB;AACnB;;AAEA,WAAW;;AAEX;EACE,gBAAgB;AAClB;;AAEA,oBAAoB;;AAEpB;EACE,gBAAgB;AAClB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');\n\nhtml,\nbody,\n#root,\n.app,\n\n.app {\n  display: flex;\n  position: relative;\n  height: 100%;\n  width: 100%;\n  font-family: 'Source Sans 3', sans-serif, 'Montserrat';\n  margin: 0px;\n  background-color:#121212;\n}\n\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: black;\n}\n\n/* Handle */\n\n::-webkit-scrollbar-thumb{\n  background: #888;\n}\n\n/* Handle on Hover */\n\n::-webkit-scrollbar-track:hover {\n  background: #555;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
