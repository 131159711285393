import { useEffect, useState } from 'react';
import { fetchsupportmessage, createHandleOpenMessgae } from '../../../global/axios';
import { ReactComponent as CloseIcon } from "../middleSection/CloseIcon.svg";
import { Pagination} from 'react-bootstrap';

const SupportTable = ({ user }) => {
  const [userData, setUserData] = useState([]);
  const [messageChanged, setMessageChanged] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSupportMessages = async (user) => {
      try {
        setLoading(true);
        const supportMessages = await fetchsupportmessage(user);
        setUserData(supportMessages.data);
        setLoading(false);

      } catch (error) {
        console.error('Error fetching support messages:', error);
      }
    };
    if (user || messageChanged) {
      fetchSupportMessages(user);
    }

  }, [user, messageChanged]);
  if (userData === null ||loading) {
    return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
    {/* <Spinner animation="border" variant="light" /> */}
    <div>no user selected</div>
</div>;
  }
  else if (userData.length === 0) {
    return <div style={{ color: "white", marginLeft: "20px" }} >There is no messages to be shown</div>
  }
  const OpenMessage = async (messageid) => {
    try {
      await createHandleOpenMessgae(messageid);
      setMessageChanged((prev) => !prev);
    } catch (error) {
      console.error('Error fetching open message:', error);
    }

  }

  let totalPages, currentItems;
  if (userData) {
    totalPages = Math.ceil(userData.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
        {number}
      </Pagination.Item>
    );
  }

  return (

    <div style={{ width: "96%" }}>
      <table className="table align-middle">
        <thead className="tableHeading" style={{ height: "50px" }}>
          <tr>
            <th className="column" style={{ backgroundColor: "#121212", color: "#FFFFFF", paddingBottom: "18px", paddingLeft: "20px" }} >ID</th>
            <th className="column" style={{ backgroundColor: "#121212", color: "#FFFFFF", paddingBottom: "18px", paddingLeft: "25px" }} >Activity</th>
            <th className="column" style={{ backgroundColor: "#121212", color: "#FFFFFF", paddingBottom: "18px" }} >Type</th>
            <th className="column" style={{ backgroundColor: "#121212", color: "#FFFFFF", paddingBottom: "18px" }} >Date Added</th>
            <th className="column" style={{ backgroundColor: "#121212", color: "#FFFFFF", paddingBottom: "18px" }} >Time</th>
            <th className="column" style={{ backgroundColor: "#121212", color: "#FFFFFF", paddingBottom: "18px" }} ></th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((messageObj) => (
            <tr key={messageObj.id}>
              <td style={{ backgroundColor: '#121212', color: '#FFFFFF', border: 'none', paddingLeft: '20px', fontSize: '12px' }}>{messageObj.id}</td>
              <td style={{ backgroundColor: '#121212', color: '#FFFFFF', border: 'none', paddingTop: '0px' }}>
                <div className={messageObj.status === 'OPEN' ? 'accountStatusActive' : 'accountStatusSuspended'} style={{ width: '60px', height: '20px', fontSize: '10px' }}>{messageObj.status}</div>
              </td>
              <td style={{ backgroundColor: '#121212', color: '#FFFFFF', border: 'none' }}>{messageObj.type}</td>
              <td style={{ backgroundColor: '#121212', color: '#FFFFFF', border: 'none' }}>{messageObj.activityDate}</td>
              <td style={{ backgroundColor: '#121212', color: '#FFFFFF', border: 'none' }}>{messageObj.activityTime}</td>

              <td style={{ backgroundColor: '#121212', color: '#FFFFFF', border: 'none', display: 'flex', alignItems: 'center' }}>
                <svg type="button" className="viewButton" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 30 30" fill="none" onClick={() => setSelectedMessageId(messageObj.id)}>
                  <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" fill="#0047FF" stroke="#2962F4" />
                  <path d="M8 17C8 13.134 11.134 10 15 10C18.866 10 22 13.134 22 17M18.8889 17C18.8889 19.1478 17.1478 20.8889 15 20.8889C12.8522 20.8889 11.1111 19.1478 11.1111 17C11.1111 14.8522 12.8522 13.1111 15 13.1111C17.1478 13.1111 18.8889 14.8522 18.8889 17Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {selectedMessageId === messageObj.id && (
                  <>
                    <div className="overlayBackground" onClick={() => setSelectedMessageId(null)}></div>
                    <div className="overlay">
                      <div className='overlaySupport' style={{ margin: "20px 0px 20px 20px" }}>
                        <div className='overlaySupportTitle'>Support Message</div>
                        <div className='overlaySupportBody'>
                          {messageObj.message}
                        </div>
                        <div className='overlaySupportType'> Type: {messageObj.type} </div>
                      </div>
                      <CloseIcon className="overlayClose" onClick={() => {
                        setSelectedMessageId(null);
                        if (messageObj.status === 'OPEN') {
                          OpenMessage(messageObj.id);
                        }
                      }} type="button" />

                    </div>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination className="paging">{items}</Pagination>

    </div>

  );
};

export default SupportTable;