import Header from "../../global/Header";
import { ProgressBar, Spinner } from "react-bootstrap";
import { fetchFullUserStat } from "../../global/axios";
import { useState, useEffect, useCallback } from "react";
import { Toast } from "react-bootstrap";

const UserStatistics = () => {
    const [fullUserStats, setfullUserStats] = useState(null);
    const [statusToast, setStatusToast] = useState(null);
    const fetchData = useCallback(async () => {
        try {
          await new Promise((resolve) => setTimeout(resolve, 500));
          const data = await fetchFullUserStat();
          setfullUserStats(data);
        } catch (error) {
          console.error("Error fetching behaviour statistics:", error);
          setStatusToast({ status: 'error', message: 'Error fetching behaviour statistics' });
        }
      }, []);

      useEffect(() => {
        fetchData();
      }, [fetchData]);
      
      if (fullUserStats === null) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }
    return (
        <div>
            <Header title={"User Statistics"} />
            <div>
                <div className="boxes">
                    <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                        <div className="boxTitle">{fullUserStats.totalNoOfRegisteredUsers}</div>
                        <div className="boxSubtitle">Total No. Of Registered Users</div>
                    </div>
                    <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                        <div className="boxTitle">{fullUserStats.activeUsersStatistics.totalDaily}</div>
                        <div className="boxSubtitle">Daily Active Users</div>
                    </div>
                    <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                        <div className="boxTitle">
                            {fullUserStats.activeUsersStatistics.totalWeekly}
                        </div>
                        <div className="boxSubtitle">
                            Weekly Active Users
                        </div>
                    </div>
                    <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                        <div className="boxTitle">
                            {fullUserStats.activeUsersStatistics.totalMonthly}
                        </div>
                        <div className="boxSubtitle">
                            Monthly Active Users
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="boxes">
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }}>
                        <div className="boxTitle" style={{ color: "white" }}>{fullUserStats.newUsersStatistics.totalDaily}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Daily New Users</div>
                    </div>
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }}>
                        <div className="boxTitle" style={{ color: "white" }}>{fullUserStats.newUsersStatistics.totalWeekly}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Weekly New Users</div>
                    </div>
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }}>
                        <div className="boxTitle" style={{ color: "white" }}>{fullUserStats.newUsersStatistics.totalMonthly}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Monthly New Users</div>
                    </div>
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }}>
                        <div className="boxTitle" style={{ color: "white" }}>{fullUserStats.newUsersStatistics.totalYearly}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                        Yearly New Users
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="boxes">
                    <div className="box" style={{ background: "#D43A2F", border: "#A71F1F" }}>
                        <div className="boxTitle" style={{ color: "white" }}>{fullUserStats.suspendedUsersStatistics.totalDaily}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Daily Suspended Users</div>
                    </div>
                    <div className="box" style={{ background: "#D43A2F", border: "#A71F1F" }}>
                        <div className="boxTitle" style={{ color: "white" }}>{fullUserStats.suspendedUsersStatistics.totalWeekly}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Weekly Suspended Users</div>
                    </div>
                    <div className="box" style={{ background: "#D43A2F", border: "#A71F1F" }}>
                        <div className="boxTitle" style={{ color: "white" }}>
                        {fullUserStats.suspendedUsersStatistics.totalMonthly} </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                        Monthly Suspended Users
                        </div>
                    </div>
                    <div className="box" style={{ background: "#D43A2F", border: "#A71F1F" }}>
                        <div className="boxTitle" style={{ color: "white" }}>
                        {fullUserStats.suspendedUsersStatistics.totalYearly}
                        </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                        Yearly Suspended Users
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="boxes">
                    <div className="box" style={{ background: "#9A9A9A", border: "#A9A9A9" }}>
                        <div className="boxTitle" style={{ color: "white" }}>{fullUserStats.deletedUsersStatistics.totalDaily}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Daily Deleted Users</div>
                    </div>
                    <div className="box" style={{ background: "#9A9A9A", border: "#A9A9A9" }}>
                        <div className="boxTitle" style={{ color: "white" }}>{fullUserStats.deletedUsersStatistics.totalWeekly}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Weekly Deleted Users</div>
                    </div>
                    <div className="box" style={{ background: "#9A9A9A", border: "#A9A9A9" }}>
                        <div className="boxTitle" style={{ color: "white" }}>
                        {fullUserStats.deletedUsersStatistics.totalMonthly}
                        </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                        Monthly Deleted Users
                        </div>
                    </div>
                    <div className="box" style={{ background: "#9A9A9A", border: "#A9A9A9" }}>
                        <div className="boxTitle" style={{ color: "white" }}>
                        {fullUserStats.deletedUsersStatistics.totalYearly}
                        </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                            Yearly Deleted Users
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex"}}>
                <div className="chart" style={{marginRight:"0px"}}>
                <div className="chartTitle"style={{paddingBottom:"10px"}}>Age</div>
                    {fullUserStats.ageRangesStatistics.map((age) => (
                        <div  style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="barLabel">{age.ageRange}</div>
                            <div style={{ width: "150px" }}>
                                <ProgressBar className="progressBar" now={age.percentage} max="100" />
                            </div>
                            <div className="barValue">{age.percentage.toFixed(2)}%</div>
                        </div>
                    ))}
                </div>
                <div className="chart">
                <div className="chartTitle" style={{paddingBottom:"10px"}}>Top cities</div>
                    {fullUserStats.citiesStatistics.slice(0, 5).map((cities) => (
                        <div  style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="barLabel">{cities.city}</div>
                            <div style={{ width: "150px" }}>
                                <ProgressBar className="progressBar" now={cities.percentage} max="100" />
                            </div>
                            <div className="barValue">{cities.percentage.toFixed(2)}%</div>
                        </div>
                    ))}
                </div>
            </div>
            {statusToast && (
                    <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                        <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            <div >
                                {statusToast.status === 'success' ? 'Success' : 'Error'}
                            </div>
                        </Toast.Header>
                        <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                    </Toast>
                )}
        </div>
    );
};

export default UserStatistics;