// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlayTopbar{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.createOverlay3{
    height: 42%;
    width: 480px !important;
    background:black;
    margin-Left: 10px;
    padding: 15px;
    color: #9A9A9A;
    border: 2px solid #252525;
    padding-Top: 25px;
    justify-Content: center;
    align-Self: center;
}

.createOverlayTitle2{
    color: #FFF;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-right: 2px;

}

.closeButton2{
    position:fixed;
    top: 10;
    right: 580px;
    height: 20px;
    width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/global/Topbar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,WAAW;IACX,iBAAiB;;AAErB;;AAEA;IACI,cAAc;IACd,OAAO;IACP,YAAY;IACZ,YAAY;IACZ,WAAW;AACf","sourcesContent":[".overlayTopbar{\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 3;\n}\n\n.createOverlay3{\n    height: 42%;\n    width: 480px !important;\n    background:black;\n    margin-Left: 10px;\n    padding: 15px;\n    color: #9A9A9A;\n    border: 2px solid #252525;\n    padding-Top: 25px;\n    justify-Content: center;\n    align-Self: center;\n}\n\n.createOverlayTitle2{\n    color: #FFF;\n    font-family: Montserrat;\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    margin-bottom: 20px;\n    justify-content: center;\n    display: flex;\n    width: 100%;\n    margin-right: 2px;\n\n}\n\n.closeButton2{\n    position:fixed;\n    top: 10;\n    right: 580px;\n    height: 20px;\n    width: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
