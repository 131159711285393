import Header from "../global/Header";
import "../globalItems/ToastMessage/toastMessage.css";
const Dashboard = () => {
    return (
        <div>
            <Header title={"Dashboard"} />
            {/*
            <Toast className="toast">
                <Toast.Header className="toastHeader">
                    <div>Bootstrap</div>
                </Toast.Header>
                <Toast.Body className="toastBody">Hello, world! This is a toast message.</Toast.Body>
            </Toast>
    */}
        </div>
    );
};

export default Dashboard;